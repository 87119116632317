import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '../../config/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(@Inject(APP_CONFIG) public config: IAppConfig) { }

  ngOnInit() {
  }

}
