<footer>
    <div class="footer">
        <div class="container">
            <div class="footer-wrapper">
                <div class="row text-center">
                    <img class="footer-pad" src="../../../assets/images/main_logo.png" alt="CellGate Logo"> 
                        <label class="footer-link">&copy; 2023 CellGate |</label>
                        <a class="footer-link" href="https://cell-gate.com/privacy-policy/" target="_blank">  Privacy Policy</a>
                </div>
                <!-- <div class="row">
                    <div class="col-md-3 col-sm-3 footer-col">

                    </div>
                    Footer Col.
                    <div class="col-md-3 col-sm-3 footer-col">
                        <div class="footer-content">
                            <div class="footer-content-logo"> <img src="../../../assets/images/main_logo.png" alt="CellGate Logo"> </div>
                            <div class="footer-content-text">
                                <p>Cellgate provides products and service for access control and monitoring over cellular networks.</p>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-3 footer-col">
                        <div class="footer-title"> PROUD MEMBER OF </div>
                        <div class="footer-content">
                            <div class="flickr_badge_wrapper">
                                <div class="flickr_badge_image" style="width:75%">
                                    <a href="http://www.americanfenceassociation.com/" target="_blank">
                                        <img src="../../../assets/images/AFAlogo.png"
                                             alt="American Fence Association" height="75" width="75" style="margin-left:-10px">
                                    </a>
                                </div>
                                <div class="flickr_badge_image" style="width:75%">
                                    <a href="https://www.cedia.net/" target="_blank">
                                        <img src="../../../assets/images/cedia.png"
                                             alt="CEDIA" height="75" width="75">
                                    </a>
                                </div>
                                <div class="flickr_badge_image" style="width:75%">
                                    <a href="https://securityindustry.org/" target="_blank">
                                        <img src="../../../assets/images/sia.png"
                                             alt="Security Industry Association" height="75" width="75">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 text-center">
                        <div class="copyright-text">&copy; 2015 CellGate</div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</footer>