import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '../services/logger.service';
import { Authenticationservice } from '../authentication/authenticationService';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: Authenticationservice, private router: Router, private logger: LoggerService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.checkUser(next, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.checkUser(route, state);
  }

  private checkUser(_route, state): boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      if(state.url.includes('reset-password')
      || state.url.includes('Account/CreateMasterUserPassword')
      || state.url.includes('accountdeletionrequest') ||
      state.url.includes('Account/Invite') ||state.url.includes('Account/CreatePassword'))
      return true;
      else
      return this.router.parseUrl('/site/dashboard');
    } else {
      this.logger.log('Not authenticated, redirecting...');
      // not logged in so redirect to login page with the return url
      return true;
      // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    }
  }
}
