import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { UTIL_MODULES } from './_app.module.constant';
import { APP_CONFIG, APP_DI_CONFIG } from './config/app.config';
import { CoreModule, ErrorsModule } from './core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Authenticationservice } from './core/authentication/authenticationService';
import { SpinnersAngularModule } from 'spinners-angular';
import {ToastrModule} from 'ngx-toastr'
import { DataTablesModule } from 'angular-datatables';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { sanitizeInputFactory } from './security/sanitize-input.function';

export function myLibInit(vvvvvvv:Authenticationservice) {
  var key = localStorage.getItem('credentials');
  if(key !== null){
    var authId = JSON.parse(key);
    return () => console.log();
  }else{
    return () => console.log();
  }
 
}
@NgModule({
  declarations:[AppComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    TranslateModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyD74yjXKpwznNrghvV1L-OtXVOnapFw4Z0',
    //   libraries: ['places', 'drawing', 'geometry'],
    // }),
    AgmMarkerClustererModule,
    FormsModule,
    ErrorsModule,
    LoadingBarHttpClientModule,
    SpinnersAngularModule,
    ReactiveFormsModule,
    DataTablesModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 30000, // 15 seconds
      progressBar: true,
    }),
    [...UTIL_MODULES]
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [Authenticationservice,
   { provide: APP_CONFIG, useValue: APP_DI_CONFIG },
    {
      provide: APP_INITIALIZER,
      useFactory: myLibInit,
      deps: [],
      multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: sanitizeInputFactory,
    deps: [DomSanitizer],
    multi: true,
  },
],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
 
})
export class AppModule { 
  
}
