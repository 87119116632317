import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Authenticationservice } from 'src/app/core/authentication/authenticationService';

@Injectable({
  providedIn: 'root'
})
export class TokenValidityResolver implements Resolve<any> {
  constructor(private authService: Authenticationservice) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let token = route.params['resetToken'];
    return this.authService.validateResetPasswordLink( token);
  }
}
