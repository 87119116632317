
   
 <div id="page-wrapper">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer class="m-top"></app-footer>
</div>
<div id="saving-overlay">
    <div class="overlay-body">
        <i class="fa fa-5x fa-spinner fa-pulse"></i><br>
        <div id="loaderDiv">
            Saving...
        </div>
    </div>
  </div>
  <div id="saving-overlay-loader" *ngIf="loader">
    <div class="overlay-body-loader">
        <i class="fa fa-5x fa-spinner fa-pulse"></i><br>
        <div id="loaderResponse">
            Please Wait...
        </div>
    </div>
  </div> 

