import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/shared/services/http';
import { MasterUserService } from '../master-user.service';

@Component({
  selector: 'app-create-master-user-password',
  templateUrl: './create-master-user-password.component.html',
  styleUrls: ['./create-master-user-password.component.scss']
})
export class CreateMasterUserPasswordComponent implements OnInit {

  username: string;
  password: string = '';
  confirmpassword:string;
  isPasswordValid:boolean= true;
  encryptedUserName: string;
  showLoginMessage:string;
  isshowLoginMessage:boolean= false;
  constructor(private router: Router,private route: ActivatedRoute, 
    private userService: UserService,private masterUserService: MasterUserService, private NS: NotificationService) { }

  ngOnInit(): void {
    let user = this.route.snapshot.data['masterUser'];
    if(!user.isSuccess && user.message =="Password already exists"){
     this.showLoginMessage=user.message;
     this.isshowLoginMessage=true;
    }
    if(user.data != null){
    this.username = user.data['username'];
    };
    this.route.queryParamMap.subscribe(params => {
      this.encryptedUserName = params['params']['userName'];
    })
  }

  save(form){
    this.isPasswordValid = this.ValidatePassword()

    if(this.isPasswordValid){
      let data = {
        "Username": this.encryptedUserName ,
        "Password": this.password
      }
      this.masterUserService.createMasterUserPassword(data).subscribe((response) => {
        if(response){
            if(response.isSuccess){
             this.NS.notifySuccess("Password Updated");
           }
          if(!response.isSuccess && response.data != null && response.data.redirect =="signin"){
            this.router.navigateByUrl("/auth/login");
          }
          else if(!response.isSuccess && response.data != null && response.data.redirect =="NonWebAccessUser"){
            sessionStorage.setItem('isnonwebaccessuserset', 'set');
            this.router.navigateByUrl("/nonwebaccessuser");
          }
          else if(!response.isSuccess && response.data != null && response.data.redirect =="WatchmanSystemConfiguration"){
            this.userService.changewatchmanStatus(true);
            this.router.navigateByUrl("/watchmansystemconfiguration");
          }
        else{
          this.router.navigateByUrl("/auth/login");
        }
        }
      });
    }

  }

  ValidatePassword() {
    var password = this.password
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(^.{8,15}@@?$)");


    if (password == "") {
      window.alert("Password cannot be empty");
      return false;
    }

    if ( !(/[0-9]/.test(password) && /[a-z]/.test(password) && password.length >= 8 && password.length <= 15)){

      window.alert("Invalid Password");
      return false;
    }

    var repeatPassword = this.confirmpassword;
    if (password !== repeatPassword) {
      window.alert("Passwords does not match");
      return false;
    }

    return true;
  }


}
