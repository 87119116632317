import { IAPIParams } from "./IapiParam";

export function APIURL({
  Accountkit,
  VoiceVideo,
  Log,
  DeviceSetting,
  DeviceAction,
  BulkImport,
  DataLayer,
  pkPassFile,
  Emailnotification,
  ExportData
}: IAPIParams) {
  return {
    pkPassFile: {
      url: `${pkPassFile}`
    },
    Accountkit: {
      url: `${Accountkit}`,
      login: `${Accountkit}/api/AccountKit/userlogin`,
      SignInAsAdmin: `${Accountkit}/api/UserDetails/SignInAsAdmin`,
      ForgotPassword: `${Accountkit}/api/AccountKit/ForgotPassword?email=`,
      ResetPassword: `${Accountkit}/api/AccountKit/ResetPassword`,
      GroupReleaseVideo: `${Accountkit}/api/User/IgnoreEnableGroupVideos`,
      validateResetPasswordLink: `${Accountkit}/api/AccountKit/validateResetPasswordLink?passwordResetToken=`,
      search: `${Accountkit}/api/AccountDetail/Search`,
      getUserDetails: `${Accountkit}/api/AccountKit/GetUserDetails`,
      getvideocallvalidation:`${Accountkit}/api/VideoCallgroup/DevicecreateCallGroupInfo`,
      getvoicecallValidation:`${Accountkit}/api/VoiceCallgroup/ValidateVoiceCallgroupdevice`,
      getDropdownLocations: `${Accountkit}/api/Dashboard/GetMapLocations?userId=`,
      getModules: `${Accountkit}/api/Dashboard/getModules?UserId=`,
      getPropertyLocationIDs: `${Accountkit}/api/useraccesscode/GetPropertyLocationIDs?AccountID=`,
      getGroupAcessPoints: `${VoiceVideo}/api/UserGroupRestriction/GetGroupAcessPoints?userGroupID=`,
      getPortImage: `${Accountkit}/api/Dashboard/GetPortImage?modulePortID=`,
      getPortImages: `${Accountkit}/api/Dashboard/GetPortsImage`,
      getAccountContacts: `${Accountkit}/api/AccountDetail/GetAccountContacts?accountId=`,
      getMapLocationUsers: `${Accountkit}/api/AccountDetail/GetMapLocationUsers?AccountID=`,
      getAccount: `${Accountkit}/api/AccountDetail/GetAccount?accountId=`,
      getContactTypes: `${Accountkit}/api/Definition/GetContactTypes`,
      getContact: `${Accountkit}/api/AccountDetail/GetContact?contactId=`,
      ToggleIgnoreCommand: `${Accountkit}/api/packet/ToggleIgnoreCommand`,
      getTimezone: `${Accountkit}/api/Definition/GetTimezones?sort=Offset ASC"`,
      deleteContact: `${Accountkit}/api/AccountDetail/DeleteContact?contactID=`,
      getCallgroupUserByUserIDAndPosition: `${Accountkit}/api/VideoCallgroup/getCountCallgroupUserByUserIDAndPositionByUserID?userID=`,
      saveOrUpdateContact: `${Accountkit}/api/AccountDetail/SaveOrUpdateContact`,
      getMapLocationUser: `${Accountkit}/api/AccountDetail/GetMapLocationUser?LocationID=`,
      getExistingUser: `${Accountkit}/api/UserDetails/GetExistingUser?AccountID=`,
      getPropertyManagerHierarchylevel: `${Accountkit}/api/UserDetails/GetPropertyManagerHierarchylevel?groupName=`,
      createUser: `${Accountkit}/api/UserDetails/CreateUser`,
      editMapLocationUser: `${Accountkit}/api/AccountDetail/EditMapLocationUser?LocationID=`,
      getBillingInfo: `${Accountkit}/api/Billing/GetBillingInfo?accountId=`,
      accountBillingTotals: `${Accountkit}/api/Billing/AccountBillingTotals?accountId=`,
      getInvoices: `${Accountkit}/api/Invoice/GetInvoices?accountId=`,
      getCardInfoForAccount: `${Accountkit}/api/Billing/GetCardInfoForAccount?accountId=`,
      getAccountCredits: `${Accountkit}/api/Billing/GetAccountCredits?accountId=`,
      getAccBillingInfo: `${Accountkit}/api/Billing/GetAccBillingInfo?accountId=`,
      getInvoiceByAccountID: `${Accountkit}/api/Invoice/GetInvoiceByAccountID?accountID=`,
      getPaymentInfo: `${Accountkit}/api/Billing/GetCardInfoForAccount?accountId=`,
      getInvoiceByInvoiceId: `${Accountkit}/api/Invoice/GetInvoiceByInvoiceId?invoiceId=`,
      saveOrUpdateAccount: `${Accountkit}/api/AccountDetail/SaveOrUpdateAccount`,
      getCallgroupUserByUserID: `${Accountkit}/api/VideoCallgroup/getCountCallgroupUserByUserID?userID=`,
      deleteAccessCodes: `${Accountkit}/api/useraccesscode/moveAccessCodeToAccessCodeRange?userID=`,
      deleteCallgroupUser: `${Accountkit}/api/VideoCallgroup/deleteCallgroupUserByUserID?userID=`,
      deleteMapLocationUser: `${Accountkit}/api/AccountDetail/DeleteMapLocationUser?LocationID=`,
      getUserID: `${Accountkit}/api/UserDetails/getUserAccessPointsByUserID?userID=`,
      getMapLocationID: `${Accountkit}/api/PropertiesLocation/getMapLocationByAccountID?accountID=`,
      getPropertyLocationID: `${Accountkit}/api/PropertiesLocation/getPropertyLocationByMapLocationID?locationID=`,
      getModuleFordeleteFromDevice: `${Accountkit}/api/UserDetails/GetModuleFordeleteFromDevice?propertyLocationID=`,
      SendEmailOnCardChange: `${Accountkit}/api/Billing/EmailCreditCardInfo?accountId=`,
      deleteCard: `${Accountkit}/api/Billing/DeleteCardForAccount?accountId=`,
      UpdateBillingInfo: `${Accountkit}/api/Billing/UpdateBillingInfo`,
      saveCard: `${Accountkit}/api/Billing/SaveCard`,
      getChargeTypes: `${Accountkit}/api/Definition/GetBillingChargeTypes`,
      getAccountInfo: `${Accountkit}/api/AccountDetail/GetAccountInfoByAccountId?accountId=`,
      getUserGroupBasedOnName: `${Accountkit}/api/UserTiers/getUserGroupBasedOnName?name=`,
      viewAccessUserID: `${Accountkit}/api/UserTiers/GetAccessUserID?userID=`,
      getUserGroupHierarchyLevel: `${Accountkit}/api/UserTiers/GetUserGroupMappings?userID=`,
      saveTrackPortShowHideLevel: `${Accountkit}//api/AccountKit/SaveTrackPortShowHideLevel`,
      getTrackPortLevel: `${Accountkit}//api/AccountKit/GetTrackPortLevel`,
      getActivity: `${Log}/api/Log/GetLogs`,
      IsAccessCodeUniqueWithWiegand: `${Accountkit}/api/useraccesscode/IsAccessCodeUniqueWithWiegand?accountID=`,
      IsAccessCodeUniqueWithWiegandInLog: `${Accountkit}/api/useraccesscode/IsAccessCodeUniqueWithWiegand?accountID=`,
      getTimezoneID: `${Accountkit}/api/UserDetails/getTimezoneByID?timezoneID=`,
      getUserTierUser: `${Accountkit}/api/UserDetails/getUserDetailsForClientPortal`,
      getUserTypeName: `${Accountkit}/api/UserDetails/getUserTypeName?userID=`,
      getUser: `${Accountkit}/api/UserDetails/getUserByID?userID=`,
      getUserAccount: `${Accountkit}/api/AccountDetail/getUser?userID=`,
      setUserPassword: `${Accountkit}/api/UserDetails/setPassword`,
      deleteUser: `${Accountkit}/api/UserDetails/deleteUser?userID=`,
      getModulePortByModulePortID: `${Accountkit}/api/AccountKit/getModulePortByModulePortID?modulePortID=`,
      getDeviceConfigInfoForUserPortal: `${Accountkit}/api/Dashboard/GetDeviceConfigInfoForUserPortal?locationID=`,
      getEntryTypeByPortTypes: `${Accountkit}/api/AccountKit/getEntryTypeByportType?portType=`,
      getModuleOutputPortTriggers: `${Accountkit}/api/Dashboard/GetModuleOutputPortTriggers?moduleID=`,
      getInputTriggersForDeviceConfig: `${Accountkit}/api/Dashboard/GetInputTriggers`,
      deleteModulePortByID: `${Accountkit}/api/AccountKit/DeleteModulePortByID?modulePortID=`,
      deleteLocationsAsync: `${Accountkit}/api/UserGroupRestriction/DeleteLocationsAsync`,
      deleteTrackPortShowHideLevel: `${Accountkit}/api/AccountKit/DeleteTrackPortShowHideLevel?moduleID=`,
      saveKitPortAuditLog: `${Accountkit}/api/data/ModulePortKitAuditLog?messageType=`,
      deleteInputOutputTriggerEVOForModulePortId: `${Accountkit}/api/AccountKit/deleteInputOutputTriggerEVOForModulePortID?modulePortID=`,
      saveDeviceConfig: `${Accountkit}/api/Dashboard/SaveDeviceConfig`,
      getPropertyLocations: `${Accountkit}/api/Dashboard/GetPropertyLocations?locationId=`,
      PostLocationChanges: `${Accountkit}/api/Dashboard/PostPropertyLocationChanges`,
      getModulePort: `${Accountkit}/api/Dashboard/GetModulePortModulePortID?modulePortID=`,
      getModulePortsByLocation: `${Accountkit}/api/Dashboard/getModulePortsByLocation?propertyLocationID=`,
      getInputTriggers: `${Accountkit}/api/AccountKit/GetInputTriggersInput?inputID=`,
      getTriggeringInputs: `${Accountkit}/api/AccountKit/getTriggeringInputs?outputID=`,
      PostModulePortChanges: `${Accountkit}/api/Dashboard/PostModulePortChanges`,
      getModuleByModuleID: `${Accountkit}/api/AccountKit/getModuleByModuleID?moduleID=`,
      getNoticeTypes: `${Accountkit}/api/Dashboard/GetNoticeTypes`,
      getNoticeModeTypes: `${Accountkit}/api/Dashboard/GetNoticeModeTypes`,
      getCarriersTypes: `${Accountkit}/api/Dashboard/GetCarriersTypes`,
      createNoticePhone: `${Accountkit}/api/Dashboard/CreateNoticePhone`,
      createNoticeEmail: `${Accountkit}/api/Dashboard/CreateNoticeEmail`,
      updateNoticePhone: `${Accountkit}/api/Dashboard/UpdateNoticePhone`,
      updateNoticeEmail: `${Accountkit}/api/Dashboard/UpdateNoticeEmail`,
      createNotice: `${Accountkit}/api/Dashboard/createNotice`,
      createCallboxAndFamilyNumbers: `${Accountkit}/api/Dashboard/CreateCallboxAndFamilyNumbers`,
      deleteFamilyNumbers: `${Accountkit}/api/Dashboard/DeleteFamilyNumbers?Id=`,
      deleteCallboxFamilies: `${Accountkit}/api/Dashboard/DeleteCallboxFamilies?Id=`,
      deleteCallboxFamiliesFalse: `${Accountkit}/api/Dashboard/deleteCallboxFamiliesFalse?Id=`,
      deleteNotice: `${Accountkit}/api/Dashboard/DeleteNotice?Id=`,
      deleteFamilyNumbersFalse: `${Accountkit}/api/Dashboard/DeleteFamilyNumbersFalse?Id=`,
      isClientPhoneUnique: `${Accountkit}/api/useraccesscode/isClientPhoneUnique?number=`,
      isClientEmailUnique: `${Accountkit}/api/useraccesscode/isClientEmailUnique?email=`,
      getUserGroupCategoriesForAddEditUserOnly: `${VoiceVideo}/api/UserGroupRestriction/GetUserGroupCategoriesForAddEditUserOnly?tenantTypeID=`,

      getPorts: `${Accountkit}/api/AccountScheduler/GetPorts?accountId=`,
      getScheduleTemplate: `${Accountkit}/api/AccountScheduler/GetScheduleTemplate?templateId=`,
      getTemplates: `${Accountkit}/api/AccountScheduler/GetTemplates?accountId=`,
      getSchedule: `${Accountkit}/api/AccountScheduler/GetSchedule?accountId=`,
      CheckSchedulerIsInOpenState: `${Accountkit}/api/AccountScheduler/CheckIsInOpenState?ScheduleID=`,
      getScheduleEntryTypes: `${Accountkit}/api/AccountScheduler/GetScheduleEntryTypes`,
      GetActiveScheduleDataByScheduleID: `${Accountkit}/api/AccountScheduler/GetActiveScheduleDataByScheduleID?scheduleID=`,
      DeleteScheduleTemplate: `${Accountkit}/api/AccountScheduler/DeleteScheduleTemplate`,
      UpdateCloseCommandForDeletedSchedule: `${Accountkit}/api/AccountScheduler/UpdateCloseCommandForDeletedSchedule`,
      DeleteSchedule: `${Accountkit}/api/AccountScheduler/DeleteSchedule`,
      SaveOrUpdateTemplate: `${Accountkit}/api/AccountScheduler/SaveOrUpdateTemplate`,
      SaveOrUpdateSchedule: `${Accountkit}/api/AccountScheduler/SaveOrUpdateSchedule`,
      checkGateIsOpenForScheduleTemplate: `${Accountkit}/api/AccountScheduler/CheckGateIsOpenForScheduleTemplate?scheduleTemplateID=`,
      GetAccountDevicesForQRCode: `${Accountkit}/api/VoiceCallgroup/GetAccountDevicesForQRCode?userId=`,

      GetAccountDevices: `${Accountkit}/api/VoiceCallgroup/GetAccountDevices?userId=`,
      GetCallGroupList: `${Accountkit}/api/VideoCallgroup/GetCallgroupList`,
      AssignCallgroupsToDevices: `${Accountkit}/api/VideoCallgroup/AssignCallgroupsToDevices`,
      SendNotifications: `${Accountkit}/api/VideoCallgroup/SendNotifications`,
      GetCallGroupInfoForID: `${Accountkit}/api/Dashboard/GetCallGroupInfoForID?Id=`,
      GetSipUsersList: `${Accountkit}/api/VideoCallgroup/GetSipUsersList?accountID=`,
      GetMasterCallgroupUsers: `${Accountkit}/api/VideoCallgroup/GetMasterCallgroupUsers?masterUserID=`,
      GetCallGroupPSTNForID: `${Accountkit}/api/VoiceCallgroup/GetCallGroupPSTNForID?callgroupID=`,
      GetCallgroupUsers: `${Accountkit}/api/VideoCallgroup/GetCallgroupUsers?callgroupID=`,
      GetInvitePendingAccept: `${Accountkit}/api/VideoCallgroup/GetInvitePendingAccept?callgroupID=`,
      AlreadyExistInInvitedUserPosition: `${Accountkit}/api/VideoCallgroup/AlreadyExistInInvitedUserPosition?callgroupID=`,
      SaveNewUserModal: `${Accountkit}/api/VideoCallgroup/SaveNewUserModal`,
      RemoveUserFromCallGroupUser: `${Accountkit}/api/Dashboard/RemoveUserFromCallGroupUser?callgroupuserID=`,
      getPSTNGroupList: `${Accountkit}/api/VoiceCallgroup/GetPSTNGroupsForAccount`,
      createWTMVoiceCallGroup: `${Accountkit}/api/VideoCallgroup/CreateVoiceWTMCallGroup`,
      DeleteVoiceWTMCallGroup: `${Accountkit}/api/VideoCallgroup/DeleteVoiceWTMCallGroup`,
      deletePSTNGroups: `${Accountkit}/api/VoiceCallgroup/RemovePSTNGroup`,
      getPstnGroupDetails: `${Accountkit}/api/VoiceCallgroup/GetPstnGroupDetails`,
      addNewPSTNGroup: `${Accountkit}/api/VoiceCallgroup/AddNewPSTNGroup`,
      updatePSTNGroup: `${Accountkit}/api/VoiceCallgroup/UpdatePSTNGroup`,
      getDeviceListFroAccount: `${Accountkit}/api/VoiceCallgroup/GetDeviceListForAccountAndUser`,
      getValdiatePSTNAlreadyExist: `${Accountkit}/api/VoiceCallgroup/ValidateDeviceHasOnlyOneGroup`,
      assignCallgroupsToDevices: `${Accountkit}/api/VoiceCallgroup/AssignCallgroupsToDevices`,
      sendNotificationsForVoiceCallGroup: `${Accountkit}/api/VoiceCallgroup/SendNotifications`,
      getProperties: `${Accountkit}/api/Image/GetProperties?accountID=`,
      isMultipleAccessCodeUniqueAtLocation: `${Accountkit}/api/useraccesscode/GetValidMultipleAccessCodes`,
      getUnusedRfidClickersAtLocation: `${Accountkit}/api/useraccesscode/GetValidUnassignedAccessCode`,
      getMultiTenant: `${Accountkit}/api/UserTiers/getMultiTenant?accountID=`,
      getAccountDetails: `${Accountkit}/api/AccountDetail/GetBaseAccount?accountId=`,
      checkAllowToAddMultipleCallgroup: `${Accountkit}/api/Callgroup/CheckAllowToAddMultipleCallgroup?accountID=`,
      getWeigandPorts: `${Accountkit}/api/useraccesscode/GetWeigandPorts?accountId=`,
      getAccessCodeTypes: `${Accountkit}/api/useraccesscode/getAccessCodeTypes`,
      GettierLeveAccessByAcount: `${Accountkit}/api/TierService/GettierLeveAccessByAcount?accountID=`,
      isClientUsernameUnique: `${Accountkit}/api/UserDetails/isClientUsernameUnique?username=`,
      saveUserDetailsFromUserWizard: `${Accountkit}/api/UserDetails/SaveUserDetailsFromUserWizard`,
      GetModuleByPropertyLocationID: `${Accountkit}/api/AccountDetail/GetModuleByPropertyLocationID?propertyLocationID=`,
      getModulesByPortIDList: `${Accountkit}/api/AccountKit/GetModulesByPortIDList?portIDList=`,
      updateAccessCodeRangeMaplocationMappings: `${Accountkit}/api/useraccesscode/updateAccessCodeRangeMaplocationMappings`,
      createCallgroupInAddUser: `${Accountkit}/api/VideoCallgroup/CreateCallgroupInAddUser?AccountID=`,
      assignCallgroupToCallgroupManagerAndSendEmail: `${Accountkit}/api/VideoCallgroup/assignCallgroupToCallgroupManagerAndSendEmail?userID=`,
      saveDeviceTOCallgroup: `${Accountkit}/api/VideoCallgroup/SaveDeviceTOCallgroup?UserID=`,
      sentInviteCallgroupManager: `${Accountkit}/api/VideoCallgroup/SentInviteCallgroupManager?Email=`,
      createWTMCallgroupForUserTier: `${Accountkit}/api/VideoCallgroup/CreateWTMCallGroup`,
      saveMasterUserToVoiceCallgroup: `${Accountkit}/api/VoiceCallgroup/saveMasterUserToVoiceCallgroup?userID=`,
      isAccessCodeUniqueAtLocation: `${Accountkit}/api/useraccesscode/GetValidAccessCode`,
      generatedAndValidatedWeigandCode: `${Accountkit}/api/accesscode/generatedAndValidatedWeigandCode`,
      isValidPhoneNumber: `${Accountkit}/api/Callgroup/IsValidPhoneNumber?phone=`,
      checkCallGroupNameUniqueExisted: `${Accountkit}/api/VideoCallgroup/CheckCallGroupNameUniqueExisted?accountId=`,
      getCallgroupListForNewUser: `${Accountkit}/api/VideoCallgroup/getCallgroupListForNewUser`,
      CheckUserHasAllowFacilityCodeAccess: `${Accountkit}/api/useraccesscode/CheckUserHasAllowFacilityCodeAccess?UserId=`,
      updateWebAccessInUserEditPage: `${Accountkit}/api/UserDetails/updateWebAccessInUserEditPage`,
      updateUserDetailInUserEditPage: `${Accountkit}/api/UserDetails/updateUserDetailInUserEditPage`,
      isAccessPhoneUnique: `${Accountkit}/api/useraccesscode/IsAccessPhoneUnique?phoneNumber=`,
      isAccessCodeUnique: `${Accountkit}/api/useraccesscode/IsAccessCodeUnique?accountID=`,
      isAccessPhoneCodeUnique: `${Accountkit}/api/useraccesscode/IsAccessPhoneCodeUnique?accessPhoneCode=`,
      getPropertyLocationIdForUser: `${Accountkit}/api/useraccesscode/GetPropertyLocationIDs?AccountID=`,
      getAssignedUserGroup: `${Accountkit}/api/UserTiers/getUserGroupByUserID?userID=`,
      getVoiceCallgroupForMasterUserID: `${Accountkit}/api/VideoCallgroup/getVoiceCallgroupForMasterUserID?masterUserID=`,
      getUserGroupProfileMapping: `${Accountkit}/api/UserTiers/getUserGroupProfileMappingByUserID?userID=`,
      sendEmailAsForgetPasswordLink: `${Accountkit}/api/UserDetails/SendEmailAsForgetPasswordLink?userID=`,
      getProperty: `${Accountkit}/api/AccountDetail/getProperty?modulePortID=`,
      getCallgroupForMasterUserID: `${Accountkit}/api/VideoCallgroup/getCallgroupForMasterUserID?masterUserID=`,
      getFirstCallgroupForUser: `${Accountkit}/api/AccountDetail/GetFirstCallgroupForUser?userID=`,
      getModuleEntryTypeLimitations: `${Accountkit}/api/Dashboard/GetModuleEntryTypeLimitations`,
      AssignCallgroupToDevice: `${Accountkit}/api/VideoCallgroup/AssignCallgroupToDevice`,
      GetTimezones: `${Accountkit}/api/Definition/GetTimezones?sort=`,
      AddNewPSTNGroup: `${Accountkit}/api/VoiceCallgroup/AddNewPSTNGroup`,
      IsCheckAccessCodeDulicateCodeByGroupID: `${Accountkit}/api/useraccesscode/IsCheckAccessCodeDulicateCodeByGroupID`,
      UpdateModuleHoldOpenUserRelationShipByUserList: `${Accountkit}/api/Kit/UpdateModuleHoldOpenUserRelationShipByUserList`,
      getUserPermissionDescription: `${Accountkit}/api/UserTiers/getUserPermissionDescription?groupID=`,
      getPropertiesByAccountID: `${Accountkit}/api/PropertiesLocation/GetPropertiesByAccountID?accountId=`,
      CheckVideoCallGroupNameUniqueExisted: `${Accountkit}/api/VideoCallgroup/CheckCallGroupNameUniqueExisted?accountId=`,
      GetValdiatePSTNAlreadyExist: `${Accountkit}/api/VideoCallgroup/GetValdiatePSTNAlreadyExist?PSTN=`,
      checkUserIsDeleted: `${Accountkit}/api/UserDetails/checkUserIsDeleted`,
      CheckWTMCallGroupReachedMaximumLimit: `${Accountkit}/api/VideoCallgroup/IsWTMCallGroupReachedMaximumLimit?accountId=`,
      createWTMCallgroup: `${Accountkit}/api/VideoCallgroup/CreateWTMCallGroup`,
      createCallGroupInfo: `${Accountkit}/api/VideoCallgroup/createCallGroupInfo`,
      UpdateWTMCallGroup: `${Accountkit}/api/Dashboard/UpdateWTMCallGroup`,
      updateCallGroupInfo: `${Accountkit}/api/VideoCallgroup/updateCallGroupInfo`,
      InviteNewUserAlreadyInvited: `${Accountkit}/api/VideoCallgroup/InviteNewUserAlreadyInvited?callgroupID=`,
      checkInvitedEmailorPhoneInAnotherAccount: `${Accountkit}/api/VideoCallgroup/checkInvitedEmailorPhoneInAnotherAccount?accountID=`,
      InviteNewUser: `${Accountkit}/api/VideoCallgroup/InviteNewUser?rphone=`,
      saveChangesViaAPI: `${Accountkit}/api/Callgroup/UpdateUserDetailsInCallgroup`,
      UpdateCallgroupDetails: `${Accountkit}/api/Dashboard/UpdateCallgroupDetails?callgroupID=`,
      saveShowZoneLevelForAccount: `${Accountkit}/api/Dashboard/SaveShowZoneLevelForAccount?accountID=`,
      GetCloseAllEntranceDataByUserID: `${Accountkit}/api/Dashboard/GetCloseAllEntranceDataByUserID?userID=`,
      PortsCurrentlyInLockdown: `${Accountkit}/api/Data/PortsCurrentlyInLockdown`,
      GetCallGroupDevices: `${Accountkit}/api/VideoCallgroup/GetCallGroupDevices?moduleID=`,
      CreateWTMHuntGroup: `${Accountkit}/api/VideoCallgroup/CreateWTMHuntGroup`,
      CreateWTMCallGroup: `${Accountkit}/api/VideoCallgroup/CreateWTMCallGroup`,
      CreateWatchmanUser: `${Accountkit}/api/VideoCallgroup/CreateWatchmanUser`,
      LockdownClear: `${Accountkit}/api/Data/LockdownClear`,
      LockdownSet: `${Accountkit}/api/Data/LockdownSet`,
      GetDashboard: `${Accountkit}/api/Data/GetDashboard`,
      PortsIHavePermissionToLockdown: `${Accountkit}/api/Data/PortsIHavePermissionToLockdown`,
      GetScheduleList: `${Accountkit}/api/AccountScheduler/GetScheduleList`,
      GetNoticeTypes: `${Accountkit}/api/Dashboard/GetNoticeTypes`,
      GetNoticeModeTypes: `${Accountkit}/api/Dashboard/GetNoticeModeTypes`,
      updateUserAccount: `${Accountkit}/api/AccountDetail/UpdateUserPassword`,
      UpdateUser: `${Accountkit}/api/AccountDetail/UpdateUser`,
      getGetAccBillingInfo: `${Accountkit}/api/Billing/GetAccBillingInfo?accountId=`,
      GetCardInfoForAccount: `${Accountkit}/api/Billing/GetCardInfoForAccount?accountId=`,
      GetAllImportScheduels: `${Accountkit}/api/AccountScheduler/GetAllImportScheduels?AccountID=`,
      ModifyScheduleImport: `${Accountkit}/api/AccountScheduler/ModifyScheduleImport`,
      ValidateindividualPhoneNumber: `${Accountkit}/api/ImportCallgroup/ValidateindividualPhoneNumber?phoneNumber=`,
      GetImportScheduleById: `${Accountkit}/api/AccountScheduler/GetImportScheduleById?scheduleId=`,
      DeleteImportSchedule: `${Accountkit}/api/AccountScheduler/DeleteImportSchedule?importScheduleId=`,
      getUserDetailsForNormalUserClientPortal: `${Accountkit}/api/UserDetails/getUserDetailsForNormalUserClientPortal`,
      isWiegandCodeUniqueAtLocation: `${Accountkit}/api/validateaccescode/GetValidAccessCode`,
      isAccessCodeUniqueWithWiegand: `${Accountkit}/api/useraccesscode/IsAccessCodeUniqueWithWiegand?accountID=`,
      convertWeigand2Code: `${Accountkit}/api/accesscode/ConvertWeigand2Code?weigandCode=`,
      getFacilityCodeFromWeigand: `${Accountkit}/api/useraccesscode/GetFacilityCodeFromWeigand?weigandCode=`,
      getMainContacts: `${Accountkit}/api/AccountDetail/GetMainContacts?accountId=`,
      CreateWTMCallGroupForVoice: `${Accountkit}/api/VideoCallgroup/CreateWTMCallGroupForVoice`,
      UpdateWTMCallGroupDetails: `${Accountkit}/api/Dashboard/UpdateWTMCallGroupDetails`
    },
    VoiceVideo: {
      url: `${VoiceVideo}`,
      // Note: QR Code now renamed as VirtualKey, TemporaryInvite as TemporaryEventKey and RecurringInvite as IndividualKey based on ticket(ENCGWM-9840)
      GetSharedQRCode: `${VoiceVideo}/api/QRCode/GetSharedQRCode?qrCodeId=`,
      ShareQRCode: `${VoiceVideo}/api/QRCode/ShareQRCode?qrCodeId=`,
      QRCodeInviteUser: `${VoiceVideo}/api/QRCode/GetQRCodeInvitationUser`,
      GetRecurringRestrictionById: `${VoiceVideo}/api/QRCode/GetRecurringRestrictionById`,
      GetUserLimitation: `${VoiceVideo}/api/QRCode/GetUserLimitations?AccountId=`,
      QRGroupAccess: `${VoiceVideo}/api/QRCode/QRGroupAccess?AccountId=`,
      DeactivateAllUserInvitesandUpdateLimit: `${VoiceVideo}/api/QRCode/DeactivateAllUserInvitesandUpdateLimit`,
      DeactivateUserLimitation: `${VoiceVideo}/api/QRCode/DeactivateUserLimitation`,
      UpdateOrCreateUserLimitations: `${VoiceVideo}/api/QRCode/UpdateOrCreateUserLimitations`,
      getCountries: `${VoiceVideo}/api/Group/GetGroupInfo`,
      getGroupAcessPointsWithGroupOrUser: `${VoiceVideo}/api/UserGroupRestriction/GetAcessPoints?userGroupID=`,
      isAddRestrictionAccess: `${VoiceVideo}/api/UserGroupRestriction/GetAddRestrictionAccess?accountID=`,
      getAppliedGroupRestriction: `${VoiceVideo}/api/UserGroupRestriction/GetAppliedGroupRestriction?accountId=`,
      getPresetRestrictionTemplates: `${VoiceVideo}/api/UserGroupRestriction/getPresetRestrictionTemplate?accountId=`,
      getUserGroupPresetRestrictionByPresetID: `${VoiceVideo}/api/UserGroupRestriction/GetUserGroupPresetRestrictionByPresetID?presetID=`,
      GetQRCodeInvitationList: `${VoiceVideo}/api/QRCode/GetQRCodeInvitationList`,
      GetTempAndPinInvitationList: `${VoiceVideo}/api/QRCode/GetTempAndPinInvitationList`,
      GetQRCodeConfigById: `${VoiceVideo}/api/QRCode/GetQRCodeConfigById`,
      GetpinCodeConfigById:`${VoiceVideo}/api/QRCode/GetPincodeConfigById`,
      GetInvitationById: `${VoiceVideo}/api/QRCode/GetInvitationById`,
      GetPinInvitationById: `${VoiceVideo}/api/QRCode/GetPincodeInvitationById`,
      CreateUpdateInvitation: `${VoiceVideo}/api/QRCode/CreateUpdateInvitation`,
      CreateUpdateTemporarypinInvitation:`${VoiceVideo}/api/QRCode/CreateUpdatePinCodeInvitation`,

      DeactivateInvites: `${VoiceVideo}/api/QRCode/DeactivateInvites`,
      GetInvites: `${VoiceVideo}/api/QRCode/GetUserQRCodeInvites?AccountId=`,
      DeactivatePinCode: `${VoiceVideo}/api/QRCode/DeactivatePinCode`,
      isPresetTemplateNameUnique: `${VoiceVideo}/api/UserGroupRestriction/CheckGroupPresetTemplateExists?templateName=`,
      savePresetTemplate: `${VoiceVideo}/api/UserGroupRestriction/SavePresetRestrictionTemplate`,
      getPresetTemplateDetails: `${VoiceVideo}/api/UserGroupRestriction/getPresetTemplateDetails?Id=`,
      deletePresetTemplate: `${VoiceVideo}/api/UserGroupRestriction/DeletePresetRestrictionTemplate?Id=`,
      ValidateCallgroupAndUsers: `${VoiceVideo}/api/VideoCallgroup/ValidateCallgroupAndUsers`,
      DeleteCallgroupAndUsers: `${VoiceVideo}/api/VideoCallgroup/deleteCallgroupAndUsers`,
      DeleteCallgroupsExceptUsers: `${VoiceVideo}/api/VideoCallgroup/DeleteCallgroupsExceptUsers`,
      GetSipInformationForUserFromAsterisk: `${VoiceVideo}/api/EVOhttp/GetSipInformationForUserFromAsterisk?UserID=`,
      getPropertyInstallLocationDeviceInformation: `${VoiceVideo}/api/UserGroupRestriction/GetPropertyInstallLocationDeviceInformation?accountID=`,
      getUserGroupCategoriesForAddEditUserOnly: `${VoiceVideo}/api/UserGroupRestriction/GetUserGroupCategoriesForAddEditUserOnly?tenantTypeID=`,
      saveUserGroupPresetRestriction: `${VoiceVideo}/api/UserGroupRestriction/SaveUserGroupPresetRestriction`,
      saveAddRestrictionAccess: `${VoiceVideo}/api/UserGroupRestriction/SaveAddRestrictionAccess`,
      SaveRestriction: `${VoiceVideo}/api/NewGroup/SaveUpdateRestriction`,
      getGroupAcessPoints: `${VoiceVideo}/api/UserGroupRestriction/GetGroupAcessPoints?userGroupID=`,
      ChangedToNoAccessGroupAsync: `${VoiceVideo}/api/UserGroupRestriction/ChangedToNoAccessGroupAsync`,
      autoGenerateGroupCode: `${VoiceVideo}/api/UserGroupRestriction/AutoGenerateGroupCode`,
      isGroupCodeUnique: `${VoiceVideo}/api/UserGroupRestriction/isGroupCodeUnique?groupCode=`,
      GetUserGroupCategoriesForUserOnly: `${VoiceVideo}/api/UserGroupRestriction/GetUserGroupCategoriesForUserOnly?tenantTypeId=`,
      IsGroupHaveCustomRestrictionUser: `${VoiceVideo}/api/UserGroupRestriction/IsGroupHaveCustomRestrictionUser?groupID=`,
      SaveGroupAndProfiles: `${VoiceVideo}/api/NewGroup/SaveGroupAndProfiles`,
      SaveGroupAccessPoints: `${VoiceVideo}/api/NewGroup/SaveGroupAccessPoints`,
      isUserGroupNameUnique: `${VoiceVideo}/api/UserGroupRestriction/isUserGroupNameUnique?name=`,
      checkAccountHaveQRCodeAccess: `${VoiceVideo}/api/QRCode/CheckAccountHaveQRCodeAccess?accountId=`,
      DeleteGroup: `${VoiceVideo}/api/Group/DeleteGroup?groupID=`,
      getTreeData: `${VoiceVideo}/api/map/tree?accountID=`,
      CheckGroupPresetTemplateExists: `${VoiceVideo}/api/UserGroupRestriction/CheckGroupPresetTemplateExists?templateName=`,
      UpdateModuleMarker: `${VoiceVideo}/api/DeviceMap/UpdateModuleMarker?ModuleID=`,
      GetInvitationDetailsForLogViewById: `${VoiceVideo}/api/QRCode/GetInvitationDetailsForLogViewById`,
      CheckDirectoryPINUniqueExisted: `${VoiceVideo}/api/VoiceVideo/CheckDirectoryPINUniqueExisted?accountId=`,
      isShowDirectoryPin: `${VoiceVideo}/api/VoiceVideo/CheckDialByAvailability`,
      UserSignout: `${VoiceVideo}/api/VoiceVideo/UserSignout?userID=`,
      GetQRAccessUserListForLoginUser: `${VoiceVideo}/api/QRCode/GetQRAccessUserListForLoginUser?loginUserID=`,
            ValidateGroupNotificationPermission:`${VoiceVideo}/api/groups/validationNoficationPermissions`,
      ValidateVoiceCallgroupNotificationPermission:`${VoiceVideo}/api/voicecallgroup/validationNoficationPermissions`,
      ValidateVideoCallgroupNotificationPermission: `${VoiceVideo}/api/videocallgroup/validationNoficationPermissions`,
      GetSharedPinCode: `${VoiceVideo}/api/PinCode/GetSharedPinCode?qrCodeId=`,
      SharePinCode: `${VoiceVideo}/api/PinCode/SharePinCode?qrCodeId=`,
      GetRandomFacilityCode:`${VoiceVideo}/api/accesscode/GetFacilityCode/`,
      checkBluetoothAccess:`${VoiceVideo}/api/account/bluetoothAccess?accountID=`,
      GetPortAccess:`${VoiceVideo}/api/GetPortAccess?userId=`,
      DeleteUserAccount:`${VoiceVideo}/api/user/UserAccountDelete`,
      ImportValidatorUsers :`${VoiceVideo}/api/ImportValidatorUser`,
      GetImportValidatorUsers :`${VoiceVideo}/api/GetImportValidatorUsers`,
      ImportValidatorUsersUsernameUnique: `${VoiceVideo}/api/ImportValidatorUsers/isUsernameUnique`
    },
    Log: {
      createUser: `${Log}/api/Group/GetGroupInfo`,
      ExportWatchmanCallgroups: `${Log}/api/Log/ExportWatchmanCallgroups`,
      ExportVoiceWatchmanCallgroups: `${Log}/api/Log/ExportVoiceWatchmanCallgroups`,
      GetImages: `${Log}/api/Log/GetImages`,
      getLogs: `${Log}/api/Log/GetLogs`,
      exportLogs: `${Log}/api/Log/ExportAccountLogs`,
      url: `${Log}`,
    },
    DeviceSetting: {
      url: `${DeviceSetting}`,
      deleteFromExridge: `${DeviceSetting}/api/http/CallgroupDeleteAccount?userid=`,
      exridgeCallgroupUserUpdate: `${DeviceSetting}/api/http/CallgroupUpdateAccount?userId=`,
      CallgroupDeleteHuntGroups: `${DeviceSetting}/api/http/CallgroupDeleteHuntGroups`,
      pushToDevice: `${DeviceSetting}/api/PSTNGroup/TalkManPushToDevice`,
      exridgeRemoveCallgroup: `${DeviceSetting}/api/http/CallgroupDeleteHuntGroups`,
      exridgeCallgroupCreation: `${DeviceSetting}/api/http/PushCallgroup?moduleID=`,
      saveToOmniPointDevice: `${Accountkit}api/OmniPoint/AddCode?DeviceSerialNumber=`,
      updateToOmniPointDevice: `${Accountkit}api/OmniPoint/UpdateCode?DeviceSerialNumber=`,
    },
    DeviceAction: {
      url: `${DeviceAction}`,
      WTMMomentaryActuationRequest: `${DeviceAction}/api/http/WTMMomentaryActuationRequest`,
      EVOWTMMomentaryActuationRequest: `${DeviceAction}/api/EVOhttp/EVOWTMMomentaryActuationRequest`,
      MomentaryActuationRequest: `${DeviceAction}/api/TalkMan/MomentaryActuationRequest`,
      U2MomentaryActuationRequest: `${DeviceAction}/api/U2http/U2MomentaryActuationRequest`,
      WTMPictureRequest: `${DeviceAction}/api/http/WTMPictureRequest`,
      EVOWTMtakePicture: `${DeviceAction}/api/EVOhttp/EVOWTMtakePicture`,
      TakePicture: `${DeviceAction}/api/TalkMan/TakePicture`,
      U2takePicture: `${DeviceAction}/api/U2http/U2takePicture`,
      IPCameraPictureRequest: `${DeviceAction}/api/http/IPCameraPictureRequest`,
      WTMLatchClose: `${DeviceAction}/api/http/WTMLatchClose`,
      EVOWTMLatchClose: `${DeviceAction}/api/EVOhttp/EVOWTMLatchClose`,
      LatchClose: `${DeviceAction}/api/TalkMan/LatchClose`,
      U2LatchClose: `${DeviceAction}/api/U2http/U2LatchClose`,
      LatchActuationCloseRequest: `${DeviceAction}/api/packet/LatchActuationCloseRequest`,
      WTMHoldOpen: `${DeviceAction}/api/http/WTMHoldOpen`,
      EVOWTMHoldOpen: `${DeviceAction}/api/EVOhttp/EVOWTMHoldOpen`,
      HoldOpen: `${DeviceAction}/api/TalkMan/HoldOpen`,
      U2HoldOpen: `${DeviceAction}/api/U2http/U2HoldOpen`,
      LatchActuationOpenRequest: `${DeviceAction}//api/packet/LatchActuationOpenRequest`,
      SetTemplateOnPorts: `${DeviceAction}/api/http/SetTemplateOnPorts`
    },
    BulkImport: {
      url: `${BulkImport}`
    },
    DataLayer: {
      url: `${DataLayer}`,
      GetPartnerMigrationStatus: `${DataLayer}/api/cellgate-partner-migration-status`,
      GetAccountPartnerMigrationStatus: `${DataLayer}/api/partner_migration_status?accountId=`,
      GetAcccountPartner: `${DataLayer}/api/cellgate-account-partner?accountId=`,
      GetPartnerMigrationCount: `${DataLayer}/api/cellgate-partner-system_usercount`,
      UpdatePartnerMigrationStatusAsAccept: `${DataLayer}/api/cellgate-partner-migration-status-as-accept`,
      GetMigrationStatus: `${DataLayer}/api/partner_migration_status`,
      AcceptRemovedUsers: `${DataLayer}/api/accept-removed-users`,
      ExistingUserMigration: `${DataLayer}/api/existing-user-migration`,
      MatchUserRecords: `${DataLayer}/api/migrate/match-user-records`,
      ExportPartnerMigrationStatus: `${DataLayer}/api/export-cellgate-partner-migration-status`,
      UserSync: `${DataLayer}/api/sync-residents`,
      ValidateErrorRecords: `${DataLayer}/api/error-records/validate`,
      GetShowImportVoiceVideoCallgroup: `${DataLayer}/api/get-show-import-voice-video-callgroup`,
      IncludeExcludedRecords: `${DataLayer}/api/include-records`,
      ExcludeErrorRecords: `${DataLayer}/api/error-records/exclude`,
      SetAccountMigrationCompleted: `${DataLayer}/api/account-migration-status`,
      ValidateMigratedProperties: `${DataLayer}/api/validate-migrated-properties`,
      GetAccountMigrationConfiguration: `${DataLayer}/api/account-migration-configuration`,
      GetPartnerMigrationUpdateLogs: `${DataLayer}/api/cellgate-partner-migration-update-log`
    },
    Emailnotification: {
      url: `${Emailnotification}`,
      SendNotifications: `${Emailnotification}/api/VideoCallgroup/SendNotifications`,
      sendNotificationsForVoiceCallGroup: `${Emailnotification}/api/VoiceCallgroup/SendNotifications`,
    },
    ExportData: {
      url: `${ExportData}`,
      // createUser: `${ExportData}/api/Group/GetGroupInfo`,
      ExportWatchmanCallgroups: `${ExportData}/api/Log/ExportWatchmanCallgroups`,
      ExportVoiceWatchmanCallgroups: `${ExportData}/api/Log/ExportVoiceWatchmanCallgroups`,
      // GetImages: `${ExportData}/api/Log/GetImages`,
      // getLogs: `${ExportData}/api/Log/GetLogs`,
      exportLogs: `${ExportData}/api/Log/ExportAccountLogs`,
    }
  };
}
