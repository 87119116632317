<div class="container error-container">
  <div class="col">
    <div
      *ngIf="
        (data?.error && data?.error == 404) ||
        (routeParams && routeParams.status == 401) ||
        !routeParams.url ||
        routeParams.status == '404' ||
        (!routeParams && !data)
      "
    >
      <h5 *ngIf="routeParams && routeParams.statusText">{{ routeParams.statusText }} :(</h5>
      <h5 *ngIf="!routeParams">Not found :(</h5>
      <p *ngIf="isChunckError" class="font-weight-bold">We got an update, please click go home to continue.</p>
      <a *ngIf="!isChunckError" class="btn" href="/auth/login">
        <h5>Go Home</h5>
      </a>
      <a *ngIf="isChunckError" class="btn" href="/">
        <h5><button class="btn btn-primary">Go Home</button></h5>
      </a>
    </div>
    <div
      class="row"
      *ngIf="!isChunckError && routeParams && routeParams?.url && routeParams.status !== '401' && routeParams.status !== '404'"
    >
      <a [routerLink]="routeParams?.url">
        <h5>Go Back to {{ routeParams?.url }}</h5>
      </a>
    </div>
    <div class="row" *ngIf="!isChunckError && routeParams.message">
      <h1>
        <span *ngIf="routeParams?.status">ERROR {{ routeParams?.status }} </span>
        <span *ngIf="routeParams?.statusText"> {{ routeParams?.statusText }}</span>
      </h1>
      <br />
      <!-- <h3 *ngIf="routeParams?.message">{{ routeParams?.message }}</h3> -->
      <!-- <br/> -->
      <!-- <h1 *ngIf="routeParams?.url && routeParams?.url !== '/'">Error in {{ routeParams?.url | uppercase }} page, sorry {{ routeParams?.user }} :(</h1> -->
      <!-- <h4 *ngIf="routeParams?.id">This error has been reported to the Administrator -->
      <!-- with the ID:<br> {{ routeParams?.id}} -->
      <!-- </h4> -->
    </div>
  </div>
</div>

<div class="pre-container" *ngIf="!isChunckError && routeParams?.message">
  <p>Error sent to the server</p>
  <pre>
    {{ this.routeParams | json }}
  </pre>
</div>
