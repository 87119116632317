import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { BareLayoutComponent } from 'src/app/layout/bare-layout/bare-layout.component';
import { CreatePasswordComponent } from '../auth/create-password/create-password.component';
import { TokenValidityResolver } from '../auth/token-validity.resolver';
import { CreateMasterUserPasswordComponent } from '../callgroup-master-user/create-master-user-password/create-master-user-password.component';
import { MasterUserService } from '../callgroup-master-user/master-user.service';
import { InviteComponent } from '../qrcode-invite/invite/invite.component';
import { QrcodeInviteService } from '../qrcode-invite/qrcode-invite.service';
import { RecurringInviteComponent } from '../qrcode-invite/recurring-invite/recurring-invite.component';
@Injectable()
export class InviteResolver implements Resolve<any> {
  constructor(private qrcodeService: QrcodeInviteService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let d = route.queryParams['d'];
    let id = route.queryParams['id'] ?? '';
    return this.qrcodeService.getQRCodeDetails(d, id);
  }

}

@Injectable()
export class MasterUserResolver implements Resolve<any> {
  constructor(private masterUserService: MasterUserService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userName = route.queryParams['userName'];
    return this.masterUserService.getMasterUserDetails(userName);
  }

}
@Injectable()
export class MasterUserGuard implements CanActivate {
  constructor(private masterUserService: MasterUserService){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):any {
    let userName = route.queryParams['userName'];
    return this.masterUserService.validateMasterUser(userName);
  }

}
const routes: Routes = 
[
  {
    path:'Invite',
     component: InviteComponent,
    resolve: {
      data: InviteResolver 
    }
  },
  {
    path:'RecurringInvite',
     component: RecurringInviteComponent,
     resolve: {
       data: InviteResolver 
     }
  },
   {
    path: '',
    component : BareLayoutComponent,
    children : [
      { 
        path: 'CreateMasterUserPassword',
        component: CreateMasterUserPasswordComponent,
        canActivate: [MasterUserGuard],
        resolve: {
          masterUser : MasterUserResolver
        },
    
      },
      { 
        path: 'CreatePassword/:resetToken',
        component: CreatePasswordComponent,
        resolve : {
          validity: TokenValidityResolver
        }
      },
    ]
  },
  { path: '**', redirectTo:'auth',pathMatch:'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes),FormsModule],
  exports: [RouterModule,FormsModule],
  providers:[InviteResolver,MasterUserGuard, MasterUserResolver]
})
export class AccountRoutingModule { }
