import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { Authenticationservice } from './authentication/authenticationService';
import { TitleService } from './services/title.service';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { UserPermissionsDirective } from './custom-directives/user-permissions.directive';
@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  exports: [RouterModule, HttpClientModule],
  providers: [
    Authenticationservice,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    TitleService
  ],
  declarations: [
    UserPermissionsDirective
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, titleService: TitleService) {
    super(parentModule);
    titleService.init();
  }
}
