<main class="container">
    <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
            <div class="panel panel-default" id="layoutPanel">
                <div class="panel-heading" id="layoutPanelHeading">
                    <strong>{{Title}}</strong>
                </div>
                <div class="panel-body" id="layoutPanelBody">
                    <p>
                        {{DisplayMessage}}
                    </p>

                    <ul class="listStyle">
                        <li>
                            <p>Click here for iOS<a class="highlightColor"> <b style="color: black;">→</b></a> <a
                                    href="https://apps.apple.com/us/app/cellgate-mobile-connect/id575775114"><img
                                        src="http://admin.zapopen.com/public/images/iOSLogo.png"> </a> </p>
                        </li>
                        <li>
                            <p>Click here for Android<a class="highlightColor"> <b style="color: black;">→</b></a> <a
                                    href="https://play.google.com/store/apps/details?id=com.celaccess&hl=en_US"><img
                                        src="http://admin.zapopen.com/public/images/AndroidLogo.png"></a> </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</main>