import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'app-non-web-access-user',
  templateUrl: './non-web-access-user.component.html',
  styleUrls: ['./non-web-access-user.component.scss']
})
export class NonWebAccessUserComponent implements OnInit {

  DisplayMessage:string = "Password has been reset. Please log into the Cellgate mobile app with your new password";
  Title:string = "Password Reset";
  state$: Observable<object>;
  constructor(private activatedRoute: ActivatedRoute,private route: ActivatedRoute,private router: Router) 
  {   }

  ngOnInit(): void {
   var data=  sessionStorage.getItem('isnonwebaccessuserset');
   
      if( data !=undefined && data!=null && data == 'set' ){
        this.DisplayMessage = this.DisplayMessage.replace('reset', 'set');
        
        this.Title = this.Title.replace('Reset', 'Set');
      }
  }
  ngOnDestroy() {
    sessionStorage.removeItem('isnonwebaccessuserset');   
  }
}
