import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { APP_DI_CONFIG } from '../../../config/app.config';
import { appToaster } from '../../../config/app-toaster.config';
import { NotificationService } from '../../../core/services/notification.service';
import { Authenticationservice } from 'src/app/core/authentication/authenticationService';


@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  
  appSettings = APP_DI_CONFIG;
  validity: any = null;
  CreatePasswordForm:UntypedFormGroup;
  isMatch=true;
  submitted=false;
  private token: string = '';
  Iserrmessage:any=false;
  ErrorMsg: string = '';
  error_messages = {

    'password': [
      { type: 'required', message: 'Invalid Password' },
      { type: 'minlength', message: 'Invalid Password'  },
      { type: 'maxlength', message: 'Invalid Password' },
      { type: 'pattern', message: 'Invalid Password'  },
    ],
    'confirmpassword': [
      { type: 'required', message: 'Invalid Confirm Password' },
      { type: 'minlength', message: 'Invalid Confirm Password' },
      { type: 'maxlength', message: 'Invalid Confirm Password'  },
      { type: 'pattern', message: 'Invalid Confirm Password'  },
    ],
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private auth: Authenticationservice,
    private notificaitonService: NotificationService
  ) 
  {
   // localStorage.clear();
  }

  ngOnInit() {
    this.CreatePasswordForm = this.fb.group({     
      password: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        Validators.pattern("^(?=.*[a-z])(?=.*[0-9])(^.{8,15})"),
      ])),
      confirmpassword: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        Validators.pattern("^(?=.*[a-z])(?=.*[0-9])(^.{8,15})"),
      ])),
    }, { 
      validators: this.password.bind(this)
    });
   /*  this.CreatePasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      conformpassword: ['',[Validators.required]],
    }); */
    this.token = this.route.snapshot.params['resetToken'];
    this.validity = this.route.snapshot.data['validity'];

    if(this.validity != null && this.validity.message == "Password reset link is not vaild"){
      this.router.navigateByUrl('/auth/login');
    }
    else if(this.validity != null && this.validity.message == "This password reset link has expired. Please request a new password reset."){
      this.Iserrmessage=true;
      this.ErrorMsg=this.validity.message;
      this.token="00000000-0000-0000-0000-000000000000";
    }
  }
  password(formGroup: UntypedFormGroup) {
    const password = formGroup.get('password');
    const  confirmPassword  = formGroup.get('confirmpassword');
    if(password?.value!='' && confirmPassword?.value!='')
    password?.value=== confirmPassword?.value ? this.isMatch=true : this.isMatch=false;
     else
     this.isMatch=true;
  }
  ResetPassword()
  {
    
   
    if(this.CreatePasswordForm.controls['password'].value==''||this.CreatePasswordForm.controls['password'].value==undefined)
    {
      alert('Password cannot be empty');
      this.CreatePasswordForm.controls['password'].markAsTouched();
      return;
    }
    
    if(this.CreatePasswordForm.controls['confirmpassword'].value==''||this.CreatePasswordForm.controls['confirmpassword'].value==undefined)
    {
      alert('Confirm password cannot be empty');
      this.CreatePasswordForm.controls['confirmpassword'].markAsTouched();
      return;
    }
    if(this.CreatePasswordForm.controls['password'].value!=this.CreatePasswordForm.controls['confirmpassword'].value)
    {
      alert('Confrim Passwords do not match');
      return;
    }
    if(this.CreatePasswordForm.invalid)
    {
      return;
    }
    const val = this.CreatePasswordForm.value;
    this.submitted=true;
    this.Iserrmessage=false;
    this.auth.ResetPassword(
  {
    password:val.password,
    confirmpassword:val.confirmpassword,
    PasswordResetToken:this.token,
  }).subscribe(res=>
    {
      if(!res.isSuccess&&res.message == "NonWebAccessUser")
      {sessionStorage.setItem('isnonwebaccessuserset', 'reset');
        this.router.navigateByUrl("/nonwebaccessuser");
      }
      else if(!res.isSuccess&&res.message == "Password reset not completed")
      {
        this.router.navigateByUrl('/auth/login');
      }
      else if(!res.isSuccess&&res.message!=null)
      {
       this.Iserrmessage=true;
       this.ErrorMsg=res.message;
      }
      else{
        this.router.navigateByUrl('/auth/login');
      }
    });

  }
}
