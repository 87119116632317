import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bare-layout',
  templateUrl: './bare-layout.component.html',
  styleUrls: ['./bare-layout.component.scss']
})
export class BareLayoutComponent implements OnInit {
  loader:any=false;
  constructor() { }

  ngOnInit() {
    document.body.className = 'app login-layout';
  }

}
