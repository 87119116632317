import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrcodeInviteService {
// Note: QR Code now renamed as VirtualKey based on ticket(ENCGWM-9840)
  constructor(private apiHandler: ApiHandler) { }

  getQRCodeDetails(guid: string, id: string) {
    let url = `${environment.API_URL.Accountkit.url}` + '/api/QRCode/showQRCode?d=' + guid + '&id=' + id;
    return this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    );

  }
  private handleError(error: any) {
    return of(error.error);
  }
}
