<div class="container-fluid bg-primary">
    <div>
        <input type="hidden" id="fileName" [value]="invite.DownloadName" />
        <input type="hidden" id="hdnBase64Data" [value]="invite.Result" />
        <div *ngIf="invite.Status" id="recurringInvite" class="mt-50">
             <div class="row" *ngIf="isShowQRCode">
                <div class="col-md-6 col-md-offset-3">
                    <p id ="h1">Scan virtual key below for entry:<p>
                    <table class="table table-sm" style="margin:auto">
                      <tbody>
                        <tr>
                          <td> Virtual Key Name </td>
                          <td> {{invite.InviteName}}</td>
                        </tr>
                        <tr *ngIf="invite.InviteType=='Recurring'">
                          <td> Sent By </td>
                          <td> {{invite.SentBy}}</td>
                        </tr>
                        <tr *ngIf="invite.InviteType=='Temporary'&& invite.StartDate && invite.EndDate">
                          <td> Start & End Date</td>
                          <td> {{invite.StartDate}} to {{invite.EndDate}} </td>
                        </tr>
                        <tr *ngIf="invite.InviteType=='Temporary'">
                          <td>Locations</td>
                          <td class="comma-list">
                            <span *ngFor="let loc of invite.Properties">{{loc}} </span>
                          </td>
                        </tr>
                        <ng-container *ngIf="invite.InviteType == 'Recurring'">
                           <tr>
                            <td> Description </td>
                            <td> {{invite.Description}} </td>
                          </tr>
                          <tr *ngFor="let week of invite.WeeklyDescriptions">
                            <td> {{week.Week}}</td>
                            <td class="comma-list">
                              <span *ngFor="let res of week.RestrictionInfo"> {{res}} </span>
                            </td>
                          </tr>
                          <tr *ngIf="invite.AccessCount">
                            <td>Access Count </td>
                            <td> {{invite.AccessCount}}</td>
                          </tr>

                        </ng-container>
                      </tbody>
                    </table>
                </div>
            </div>
            <div class="row text-center" *ngIf="invite.InviteType == 'Recurring'&& isShowQRCode">
                <img class="brand-btn-alert" src="assets/brandIcons/Alert_Icon.svg">
             
              </div>
            <div class="row" *ngIf="invite.InviteType == 'Recurring'&& isShowQRCode ">
                <div class="text-center col-md-6 col-md-offset-3">
                 <p id="h1"> DO NOT SCREENSHOT THE QR CODE BELOW</p>
              <br>
              </div>
               </div>
            <div class="row" id="values" *ngIf="isShowQRCode">
                <div class="col-md-6 col-md-offset-3">
                    <img id="barcodeImage" [src]="'data:image/png;base64,'+ invite.Result" />
                </div>
            </div>
           
               <div class="row" *ngIf="invite.InviteType == 'Recurring' && !isShowQRCode">
                <p class="" id ="h1"> Please click on a link below to view your QR Code</p>
            
              </div>
              <div class="row" *ngIf="invite.InviteType == 'Recurring' && !isShowQRCode">
              <div class=" col-md-6 col-md-offset-3">
                <table class="table table-sm" style="margin:auto">
                <tbody>
                    <tr>
                        <td> Virtual Key Name </td>
                        <td> {{invite.InviteName}}</td>
                    </tr>
                    <tr>
                        <td> Sent By </td>
                        <td> {{invite.SentBy}}</td>
                    <!-- </tr>
                    <tr *ngIf="invite.StartDate && invite.EndDate">
                        <td> Start & End Date</td>
                        <td> {{invite.StartDate}} to {{invite.EndDate}} </td>
                    </tr> -->
                                           
                </tbody>
            </table>
            </div>
        </div>
        <div class="row text-center" *ngIf="invite.InviteType == 'Recurring'&& !isShowQRCode">
            <img class="brand-btn-alert" src="assets/brandIcons/Alert_Icon.svg">
         
          </div>
              <div class="row" *ngIf="invite.InviteType == 'Recurring'&& !isShowQRCode ">
               <div class="text-center">
                <p class="text-warning-msg"  > DO NOT SCREENSHOT THE QR CODE as the code refreshes after each use</p>
             <br>
             </div>
              </div>
              <div class="row text-center" *ngIf="invite.InviteType == 'Recurring'&& !isShowQRCode">
                <button (click)="GenerateQRCode()" ><img class="brand-btn-qrcode" src="assets/brandIcons/Generate_QR_code.svg"></button>
             <br>
              </div>
               <div class="row" *ngIf="invite.InviteType == 'Recurring'">
               <p class="align" id ="h1"> Options to save to your phone:</p>
            <br>
           
                <div class="col-md-6 col-md-offset-3 " id="wallets">
                        <!-- <button class="btn btn-default" (click)="SaveCalender()">Add To Calender</button> -->
                        <div class="text-center">
                            <p id="h1">Option 1:</p>
                            <p>Click below to save to your wallet</p>
                        </div>
                    
                        <div class="text-center bg">
                       
                        
                            <button (click)="SavePkPass(invite.ID)">
                                <img class="brand-btn-apple" src="assets/brandIcons/US-UK_Add_to_Apple_Wallet_RGB_101421.svg">
                            </button>
                            <button (click)="AddToGoogleWallet(invite.ID)">
                                <img class="brand-btn-google" src="assets/brandIcons/enUS_add_to_google_wallet_add-wallet-badge.svg">
                            </button>
                        
                    </div>
                    </div>
                
                <div class="col-md-6 col-md-offset-3 " id="wallets1" >
                
                    <div class="text-center">
                        <p id ="h1">Option 2:</p><p>click below to save to your Calendar</p>
                    </div>
                    <div class="text-center">
                        <button (click)="SaveCalendar(invite.ID)">
                            <img class="brand-btn-calender" src="assets/brandIcons/calendar.svg">
                        </button>
                   
                </div>
                </div>
               
                
            </div>
        </div>
        <div *ngIf="!invite.Status" class="mt-100">
            {{invite.Result}}
        </div>
    </div>

<script>

    var fileName = document.getElementById("fileName").value;
    var base64Data = document.getElementById("hdnBase64Data").value;
    var a = document.createElement("a"); //Create <a>
    a.href = "data:image/png;base64," + base64Data;
    a.download = fileName; //File name Here
    a.click(); //Downloaded file
</script>
