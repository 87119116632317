import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { publish,refCount} from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notification: BehaviorSubject<string> = new BehaviorSubject<any|null>(null);
  readonly notification$: Observable<string> = this._notification.asObservable().pipe(publish(), refCount());
  private notificationConfig: {};
  constructor(public toastr: ToastrService) {
    this.notificationConfig = {
      enableHtml: true,
      closeButton: true,
      newestOnTop: true,
      maxShown: 30000,
      positionClass: 'toast-bottom-right'
    };
  }

  notifySuccess(m: any) {
    setTimeout(() => this.toastr.success(m, '', this.notificationConfig));
  }

  notifyError(m: any) {
    setTimeout(() => this.toastr.error(m, '', this.notificationConfig));
  }

  notifyWarning(m: any) {
    setTimeout(() => this.toastr.warning(m, '', this.notificationConfig));
  }

  notifyInfo(m: any) {
    setTimeout(() => this.toastr.info(m, '', this.notificationConfig));
  }
  addsaving(){
    document.body.classList.add('saving');
  }
  removesaving(){
    document.body.classList.remove('saving');
  }
  addloading(){
    document.body.classList.add('loading');
  }
  removeloading(){
    document.body.classList.remove('loading');
  }
}
