
<main class="container">
    <div class="row">
        <div class="col-sm-9 col-sm-offset-2">
            <div class="panel panel-default" id="layoutPanel">
                <div class="panel-heading" id="layoutPanelHeading">
                    <strong>Watchman System Configuration</strong>
                </div>
                <div class="panel-body" id="layoutPanelBody">
                     <video controls style="width: 818px; height: 361px;" [src]="videoURL" type="video/mp4" autoplay> </video>
                </div>
            </div>
        </div>
    </div>
    <p class="wtm-description">
        Your property has invested a significant amount to increase your security and convenience.  You can learn more about Watchman by going to cell-gate.com/watchman.  Watchman allows you to have a live video conversation with a visitor, using your smart phone, anywhere you are.
        You will be able to create the users in your apartment, home or business that you want to receive calls and the order in which they are received.  Watchman uses an app on iPhone or Android to receive calls.  The system will call up to 3 people simultaneously.
        If nobody answers within 30 seconds, then a second group of up to 3 people can be called.  Finally, if nobody answers, it can call a voice only number like a landline or cellphone voice number, but of course this will only have audio like a telephone entry system.

    </p>
</main>
