import { Component, HostListener, OnInit } from '@angular/core';

import { ActivatedRoute , Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import { Authenticationservice } from './core/authentication/authenticationService';
import { NotificationService } from './core/services/notification.service';
import { UserService } from './shared/services/http';
import { handleKeyPress, handlePaste, handleInput } from './security/input-handler.function';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // isLoading:false;
  title = 'cellgateweb';
  name = 'Translation';
  showNav:boolean=false;
  canRemove:boolean =false;
  constructor(private route : ActivatedRoute, private router : Router,private NS:NotificationService,
    private authService: Authenticationservice, private userService: UserService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart){
        this.showNav=event.url.includes('signin-admin');
      this.canRemove = (event.url.includes('reset-password')
      || event.url.includes('Account/CreateMasterUserPassword')
       ||event.url.includes('Account/CreatePassword'))
        if(this.showNav){
         localStorage.clear();
        }
        else if(this.canRemove)
        {
          localStorage.clear();
        }
        else if(event.url == "/"){
          const key = this.authService.getKey();
          if(key != null){
             this.authService.getUserDetails(key).subscribe((res) => {
             if(res == undefined && res == null){
            //  this.authService.logout();
                localStorage.clear();
              this.router.navigate(['/auth/login']);     
             }else{
              this.routeUser(res.user, res.callgroup);
             }
            });
          }else{
            this.router.navigate(['/auth/login']);
          }
        }

      this.NS.addloading();
      }else if(event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError){
      this.NS.removeloading();
      } 
    })
  }
  routeUser(USER, CALLGROUP) {
    if (USER) {
      if(!USER.IsAccountActive) {
        this.router.navigateByUrl('/site/inactive');
      }else{
        if (USER.UserTypeID == 2 || USER.UserTypeID == 9) {
          if (USER && CALLGROUP) {
  
            if (USER.UserTypeID == 2 && USER.EnableUserTierCallgroup) {
              USER.callgroupId = CALLGROUP.callgroupId;
              this.userService.Callgroup_ID = CALLGROUP.callgroupId;
              this.router.navigateByUrl('/site/videocallgroup/callgroup/edit');
  
            }
            else if (USER.UserTypeID == 2) {
              // $urlRouterProvider.otherwise('/location/' + CALLGROUP.locationID + '/callgroup/' + CALLGROUP.callgroupId + '/module/' + CALLGROUP.moduleID);
              this.userService.Callgroup_ID = CALLGROUP.callgroupId;
              this.router.navigateByUrl('/site/videocallgroup/callgroup/edit');
  
  
            } else if (USER.UserTypeID == 9) {
              // $urlRouterProvider.otherwise('pstngroup/edit/' + CALLGROUP.callgroupId);
              this.userService.PSTNGROUP_ID = CALLGROUP.callgroupId;
              this.router.navigateByUrl('/site/voicecallgroup/pstngroup/edit');
              // $urlRouterProvider.otherwise('pstngroup/edit/');
            }
  
          }
          else{
            this.router.navigate(["/auth/login"]);
          }
        }
  
        else {
          if (USER.IsBillingAdminLoginLandpage) {
            this.router.navigateByUrl('/site/billing');
          } else if (USER.IsUserDashboard == 3) {
            this.authService.logout();
            //this.router.navigateByUrl('site/mywebaccount');
          } else {
            this.router.navigateByUrl('/site/dashboard');
          }
  
        }
      }
    }
  }

  
  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event);
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    handlePaste(event);
  }

  @HostListener('document:input', ['$event'])
  onInput(event: InputEvent){
    handleInput(event);
  }

}
