import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG, APP_DI_CONFIG, IAppConfig } from '../../config/app.config';
import { Authenticationservice } from '../authentication/authenticationService';
import { AdminLocalStorageService } from '../authentication/admin.local.storage.service';
import { publicKey } from './config';
import * as Forge from 'node-forge'
@Injectable({
  providedIn: 'root'
})
export class ApiHandler {
  constructor(private http: HttpClient, private authService: Authenticationservice,
    private _localStorage: AdminLocalStorageService) {}
 
  private formatErrors(error: any) {
    // return Observable.throw(error.error); // use this for subscribe(error:) to fire
    // Let the app keep running by returning an empty result.
    return of(error.error);
  }
  encryptLargeData(data: any): string {
    const rsa = Forge.pki.publicKeyFromPem(publicKey);
    const jsonData = data;
    const chunkSize = 86; // Adjust based on RSA key size and padding scheme
    let finalEncrypted = '';
   
    // Encrypt data in chunks
    for (let i = 0; i < jsonData.length; i += chunkSize) {
      const chunk = jsonData.substring(i, i + chunkSize);
      const encryptedChunk = rsa.encrypt(chunk);
      finalEncrypted = finalEncrypted +  Forge.util.encode64(encryptedChunk) + "check";
    }
   
    return finalEncrypted;
  }
  encryptData(data: any,path:string): string {
    if(publicKey != null && publicKey != undefined){
        const rsa = Forge.pki.publicKeyFromPem(publicKey);
      //const encrypted = window.btoa(rsa.encrypt(JSON.stringify(data).toString()));
      const encrypted =this.encryptLargeData(JSON.stringify(data));
          let value = '';
          if (
            !path.includes('api/AccountKit/userlogin') &&
            !path.includes('api/AccountKit/GetUserDetails') &&
            !path.includes('api/UserDetails/SignInAsAdmin')
          ) {
            value = this.encryptString(this._localStorage.getKey(), path);
          }
          return JSON.stringify({
            "Data": encrypted.toString(),
            "Value" : value
          });
        }
     return JSON.stringify(data);
  }
  encryptString(value: any,path:string): any {
    if(publicKey != null && publicKey != undefined && value != null){
        const rsa = Forge.pki.publicKeyFromPem(publicKey);
        //const encrypted= window.btoa(rsa.encrypt(value.toString()));
        const encrypted =this.encryptLargeData(value.toString());
        return encrypted.toString();
      }
    return value;
  }

  private EncryptAuthKey(): string {   
    let authKey = this._localStorage.getKey();
    if(authKey != null && authKey != undefined && authKey != ''){
      let encryptValue:string;

      if(publicKey != null && publicKey != undefined){
        const rsa = Forge.pki.publicKeyFromPem(publicKey);
       // const encrypted= window.btoa(rsa.encrypt(authKey.toString()));
        const encrypted =this.encryptLargeData(authKey.toString());
        encryptValue =encrypted.toString();
        if(encryptValue != null && encryptValue != undefined && encryptValue != '')        
            return "&value=" + encodeURIComponent(encryptValue);     
      }
    }
    return '';
  }

  public encryptParams(paramsString: string,path:string): string {
    let encryptValue:string;
    const params = new URLSearchParams(paramsString);
    let encryptedParams = new URLSearchParams();
    if(publicKey != null && publicKey != undefined && paramsString != null){
      const rsa = Forge.pki.publicKeyFromPem(publicKey);
     // const encrypted= window.btoa(rsa.encrypt(paramsString.toString()));
      const encrypted =this.encryptLargeData(paramsString.toString());
      encryptValue =encrypted.toString();
      if(encryptValue != null && encryptValue != undefined && encryptValue != '')
        {
          return "request="+ encodeURIComponent(encrypted.toString()) + this.EncryptAuthKey();
        }
       return encrypted.toString();
    }
      return paramsString;
  }
 
  private convertFormData(formData: any, path: string): any {
    if (!(formData instanceof FormData)) {
      return this.encryptData(formData, path);
    }
    const encryptedFormData = new FormData(); 
    formData.forEach((value, key) => {
      if (value instanceof File) {
        encryptedFormData.append(key, value);
      } else {
        const stringValue = value.toString();
        const encryptedValue = this.encryptString(stringValue, path);
        encryptedFormData.append(key, encryptedValue);
      }
    }); 
    return encryptedFormData;
  }
 
 
  private convertHttpParamsToQueryString(params: HttpParams): string {
    return params.keys().map(key => `${key}=${params.get(key)}`).join('&');
  }
  get(path: string, params: HttpParams = new HttpParams(), options?: any): Observable<any> {
    let url = /^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`;
    let encryptedParamsString = '';
    let encryptedParams = params;
    if(params.keys().length > 0)
      {
        encryptedParamsString =  this.encryptString( this.convertHttpParamsToQueryString(params).toString(),path);
       if(encryptedParamsString != null && encryptedParamsString != undefined && encryptedParamsString != '')
        encryptedParams = new HttpParams().set('request', encodeURIComponent(encryptedParamsString.toString()));
       
      }
    const [basePath, queryString] = url.split('?');
    encryptedParamsString = '';
    
    if (queryString) {
      encryptedParamsString = this.encryptParams(queryString,path);
    }else{
      encryptedParamsString = this.EncryptAuthKey().replace("&", "");
    }
 
   url = encryptedParamsString ? `${basePath}?${encryptedParamsString}` : basePath;
 
    return this.http
      .get(url, { headers: options,params : encryptedParams })
      .pipe(catchError(this.formatErrors));
  }
  put(path: string, body: Object = {}, headers: any = {}): Observable<any> {
    headers["Content-Type"] = "application/json";
    let url = /^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`;
    const [basePath, queryString] = url.split('?');
    let encryptedParamsString = '';
    if (queryString) {
      encryptedParamsString = this.encryptParams(queryString,path);
    }
    url = encryptedParamsString ? `${basePath}?${encryptedParamsString}` : basePath;

    return this.http.put(url, encryptedParamsString ? JSON.stringify(body) : this.encryptData(body, path), { headers: headers }
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, headers: any = {}): Observable<any> {
    headers["Content-Type"] = "application/json";
    let url = /^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`;
    const [basePath, queryString] = url.split('?');
    let encryptedParamsString = '';
    if (queryString) {
      encryptedParamsString = this.encryptParams(queryString,path);
    }    
    url = encryptedParamsString ? `${basePath}?${encryptedParamsString}` : basePath;

    return this.http.post(url, encryptedParamsString ? JSON.stringify(body) : this.encryptData(body, path),
      { headers: headers },
    ).pipe(catchError(this.formatErrors));
  }
  
  postEmail(path: string, body: Object = {}, options: any = { headers : {}}): Observable<any> {
    options.headers["Content-Type"] = "application/json";
        let url = /^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`;
        const [basePath, queryString] = url.split('?');
        let encryptedParamsString = '';
        if (queryString) {
          encryptedParamsString = this.encryptParams(queryString,path);
        }
        url = encryptedParamsString ? `${basePath}?${encryptedParamsString}` : basePath;
        return this.http.post(url,
          encryptedParamsString ? JSON.stringify(body):this.encryptData(body,path),
          options,
            ).pipe(catchError(this.formatErrors));
  }
  delete(path: any): Observable<any> {
    return this.http.delete(`${environment.API_URL}${path}`).pipe(catchError(this.formatErrors));
  }
 
  postForImport(path: string, body: Object = {}, options?: any): Observable<any> {
    return this.http.post(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`,
      this.convertFormData(body, path),
      options,
        ).pipe(catchError(this.formatErrors));
  }
}
