import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationModel } from './authentication.model';
import { BehaviorSubject, catchError,firstValueFrom, map, Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { APP_CONFIG, APP_DI_CONFIG, IAppConfig } from '../../config/app.config';
import { AdminLocalStorageService } from './admin.local.storage.service';
import { UserService } from 'src/app/shared/services/http';
import { ApiHandler } from '../services/api.handler';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { privateKey } from '../../core/services/config';
import * as Forge from 'node-forge';
export interface Credentials {
  // Customize received credentials here
  user: AuthenticationModel;
  token: string;
}

const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root'
})
export class Authenticationservice {
  constructor(
    private http: HttpClient,private apiHandler: ApiHandler,
     private notificationService: NotificationService, private userService:UserService,
    private _localStorage: AdminLocalStorageService,private router: Router,
    @Inject(APP_CONFIG) public config: IAppConfig
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
    }
  }

  UserSignout(userid:any){
      return this.apiHandler
      .get(environment.API_URL.VoiceVideo.UserSignout + userid)
      .pipe(
        map((res) => {

          return res;
         })
      )
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this._localStorage.setCredentials();
      return of(true);
  }
  validateResetPasswordLink(token: any){
    return this.apiHandler
      .get(environment.API_URL.Accountkit.validateResetPasswordLink+ token)
      .pipe(
        map((res) => {
          return res;
         })
      )
  }
ResetPassword(_context: any)
{
return this.apiHandler.post(environment.API_URL.Accountkit.ResetPassword, {
  password: _context.password,
  confirmpassword: _context.confirmpassword,
  PasswordResetToken:_context.PasswordResetToken,
}).pipe(
  map((res) => {
   return res;
  })
)
}
ValidateUserName(data:any)
{
  return this.apiHandler.
  post(environment.API_URL.VoiceVideo.url+"/api/VoiceVideo/IsUsernameValid", data)
  .pipe(
    map((respose) => {
        return respose;
    }))
  .pipe(catchError(this.handleError));
}
  login(_context: any): Observable<Credentials> {
     return this.apiHandler
      .post(environment.API_URL.Accountkit.login, {
        username: _context.username,
        password: _context.password
      })
      .pipe(
        map((res) => {
          if (res.user) {
            let obj = {
              authToken: res.AuthToken,
              authKey: res.user.SessionID,
              csrf: res.CsrfToken
              //authId:res.user.UserID
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            res.user.StripePublishKey = this.decryption(res.S_PK);
            res.user.StaxWebToken = this.decryption(res.S_WT);
            res.user.SHOW_VIDEO_GROUP = res.ShowVideoGroup;
            res.user.SHOW_VOICE_GROUP = res.ShowVoiceGroup;
            res.user.SHOW_IMPORT_VOICE_GROUP = res.showImportVoiceGroup;
            res.user.GROUP_RELEASE_VIDEO_URL = res.GroupReleaseVideoUrl;
            res.user.GROUP_RELEASE_VIDEO_CONTENT = res.GroupReleaseVideoContent;
            res.user.IS_USER_IGNORE_GROUP_RELEASE_VIDEO = res.IsUserIgnoreGroupReleaseVideo;
            res.user.ENABLE_GROUP_RELEASE_VIDEO =res.EnableGroupReleaseVideo;
            res.user.IS_USER_IGNORE_RELEASE=res.IsUserIgnoreRelease;
            res.user.RELEASE_CONTENT=res.ReleaseContent;
            res.user.RELEASE_MAIL_ID=res.ReleaseMailID;
            res.user.UserPortal = res.UserPortal;
            res.user.GoogleAPIKey = this.decryption(res.G_AK);
            res.user.AzureMapsKey = this.decryption(res.A_MK);
            this.userService.changeUserDetail(res.user);
            this.userService.changeAccountDetail(res);
            this._localStorage.setCredentials(obj);
            return res;
          } else {
            return res;
          }
        })
      )
      .pipe(
        // catchError((e: { error: { message: any; error_info: any; }; }) => {
        //   e.error.message = e.error.message ? e.error.message : 'Faile to login.';
        //   this.notificationService.notifyError(e.error.error_info ? e.error.error_info : e.error.message);
        //   return of(false);
        // })
      );
  }
  decryption(value: any): any {  
   
      if(privateKey != null && privateKey != undefined){
        const rsa = Forge.pki.privateKeyFromPem(privateKey);
        var ctBytes = Forge.util.decode64(value);    
        var plaintextBytes = rsa.decrypt(ctBytes);
        return plaintextBytes.toString();
    }
  return value;
}
  SignInAsAdmin(data: any): Observable<Credentials> {
    return this.apiHandler
     .post(environment.API_URL.Accountkit.SignInAsAdmin, data)
     .pipe(
       map((res) => {
         if (res.user) {
           let obj = {
             authToken: res.AuthToken,
             authKey: res.user.SessionID,
             csrf: res.CsrfToken
            //  authId:res.user.ID
           };
           // store user details and jwt token in local storage to keep user logged in between page refreshes
           // localStorage.setItem('currentUser', JSON.stringify(user));
          res.user.SHOW_VIDEO_GROUP = res.ShowVideoGroup;
          res.user.SHOW_VOICE_GROUP = res.ShowVoiceGroup;
          res.user.SHOW_IMPORT_VOICE_GROUP = res.showImportVoiceGroup;
           res.user.StripePublishKey = this.decryption(res.S_PK);
          res.user.StaxWebToken = this.decryption(res.S_WT);
           res.user.GROUP_RELEASE_VIDEO_URL = res.GroupReleaseVideoUrl;
           res.user.GROUP_RELEASE_VIDEO_CONTENT = res.GroupReleaseVideoContent;
           res.user.IS_USER_IGNORE_GROUP_RELEASE_VIDEO = res.IsUserIgnoreGroupReleaseVideo;
           res.user.ENABLE_GROUP_RELEASE_VIDEO =res.EnableGroupReleaseVideo;
           res.user.IS_USER_IGNORE_RELEASE=res.IsUserIgnoreRelease;
           res.user.RELEASE_CONTENT=res.ReleaseContent;
           res.user.RELEASE_MAIL_ID=res.ReleaseMailID;
            res.user.GoogleAPIKey = this.decryption(res.G_AK);
            res.user.AzureMapsKey = this.decryption(res.A_MK);
           this.userService.changeUserDetail(res.user);
           this.userService.changeAccountDetail(res);
           this._localStorage.setCredentials(obj);
           return res;
         } else{
           return false;
         }
       })
     )
     .pipe(
     );
 }
 GroupReleaseVideo(userid)
 {
  return this.apiHandler.post(`${environment.API_URL.Accountkit.GroupReleaseVideo}`,{
    'UserId':userid,
    'IsIgnore':true
}).pipe(map((res)=>{
  if(res)
  return res;
}));
 }
 IgnoreNewReleaseContent(userid)
 {
  return this.apiHandler.post(`${environment.API_URL.Accountkit.url}`+ "/api/User/IgnoreReleaseContent",{
    'UserId':userid,
    'IsIgnore':true
}).pipe(map((res)=>{
  if(res)
  return res;
}));
 }
   getUserDetails(authkey: any):Observable<any> {
    let data={
      authkey:authkey
    }
    return  this.apiHandler
      .post(`${environment.API_URL.Accountkit.getUserDetails}`,data)
      .pipe(map((res) => {
          if(res){
            if(res.type == 'error'){
              localStorage.clear();
            }else{
              let obj = {
                authToken: res.AuthToken,
                authKey: res.user.SessionID,
                csrf: res.CsrfToken
               //  authId:res.user.ID
              };
              res.user.StripePublishKey =this.decryption( res.S_PK);
            res.user.StaxWebToken = this.decryption(res.S_WT);
              res.user.SHOW_VIDEO_GROUP = res.ShowVideoGroup;
              res.user.SHOW_VOICE_GROUP = res.ShowVoiceGroup;
              res.user.SHOW_IMPORT_VOICE_GROUP = res.showImportVoiceGroup;
              res.user.GROUP_RELEASE_VIDEO_URL = res.GroupReleaseVideoUrl;
              res.user.GROUP_RELEASE_VIDEO_CONTENT = res.GroupReleaseVideoContent;
              res.user.IS_USER_IGNORE_GROUP_RELEASE_VIDEO = res.IsUserIgnoreGroupReleaseVideo;
              res.user.ENABLE_GROUP_RELEASE_VIDEO =res.EnableGroupReleaseVideo;
              res.user.IS_USER_IGNORE_RELEASE=res.IsUserIgnoreRelease;
              res.user.RELEASE_CONTENT=res.ReleaseContent;
              res.user.RELEASE_MAIL_ID=res.ReleaseMailID;
              res.user.UserPortal = res.UserPortal;
              res.user.GoogleAPIKey = this.decryption(res.G_AK);
              res.user.AzureMapsKey = this.decryption(res.A_MK);
              this.userService.changeUserDetail(res.user);
              this.userService.changeAccountDetail(res);
              this._localStorage.setCredentials(obj);
            }
          }
        //  this._localStorage.setCredentials(obj);
          return res;

        })
      );
  }
  getUserDetailsModule():Observable<any> {
    let authkey = this.getKey();
    let data={
      authkey:authkey
    }
    return  this.apiHandler
      .post(`${environment.API_URL.Accountkit.getUserDetails}`,data)
      .pipe(map((res) => {
          if(res){
            if(res.type == 'error'){
              localStorage.clear();
            }else{
            res.user.StripePublishKey = this.decryption(res.S_PK);
            res.user.StaxWebToken = this.decryption(res.S_WT);
            res.user.SHOW_VIDEO_GROUP = res.ShowVideoGroup;
            res.user.SHOW_VOICE_GROUP = res.ShowVoiceGroup;
            res.user.SHOW_IMPORT_VOICE_GROUP = res.showImportVoiceGroup;
            res.user.GROUP_RELEASE_VIDEO_URL = res.GroupReleaseVideoUrl;
            res.user.GROUP_RELEASE_VIDEO_CONTENT = res.GroupReleaseVideoContent;
            res.user.IS_USER_IGNORE_GROUP_RELEASE_VIDEO = res.IsUserIgnoreGroupReleaseVideo;
            res.user.ENABLE_GROUP_RELEASE_VIDEO =res.EnableGroupReleaseVideo;
            res.user.IS_USER_IGNORE_RELEASE=res.IsUserIgnoreRelease;
            res.user.RELEASE_CONTENT=res.ReleaseContent;
            res.user.RELEASE_MAIL_ID=res.ReleaseMailID;
            res.user.UserPortal = res.UserPortal;
            res.user.GoogleAPIKey = this.decryption(res.G_AK);
            res.user.AzureMapsKey = this.decryption(res.A_MK);
            this.userService.Callgroup_ID = res.callgroup?.callgroupId;
            this.userService.PSTNGROUP_ID = res.callgroup?.callgroupId;
            this.userService.loadScript(res);
            this.userService.changeUserDetail(res.user);
            this.userService.changeAccountDetail(res);
          }
        }
          return true;

        })
      );
  }

  public getToken(): string | null {
    return this._localStorage.getToken();
  }
  public getKey(): string | null {
    return this._localStorage.getKey();
  }
  public getAuthId(): string | null {
    return this._localStorage.getAuthId();
  }
  public getCsrfToken(): string | null {
    return this._localStorage.getCsrfToken();
  }
  public isAuthenticated(): boolean {
    const token = this._localStorage.getToken();
    return token != null;
  }

  public getloggedInUser() {
    // const savedCredentials = this._localStorage.getCredentials()
    const savedCredentials = this._localStorage.getCurrentUser();

    return savedCredentials ? savedCredentials : null;
  }

  getUserRole(): Observable<string> {
    const savedCredentials = this.getloggedInUser();
    return of(savedCredentials['role']);
  }
  isSuperAdmin() {
    const savedCredentials = this.getloggedInUser();
    if (!savedCredentials) {
      return false;
    }
    if (savedCredentials['role'] === this.config.UserRoleConstant.SuperAdmin) {
      return true;
    }
    return false;
  }
  getUserPermittedModules() {
    return this._localStorage.getCurrentUserPermittedModules();
  }

//   minimizeSidebar(minimize = true) {
//     const credentials = this._localStorage.getCredentials();
//     const savedCredentials = credentials ? JSON.parse(credentials) : null;
//     if (!savedCredentials) {
//       return null;
//     }

//     if (minimize) {
//       savedCredentials.sidebar_minimize = minimize;
//     } else {
//       delete savedCredentials['sidebar_minimize'];
//     }

//     this._localStorage.setCredentials(savedCredentials);
//   }
  isSidebarMinimized() {
    const savedCredentials = this._localStorage.getCurrentUser();
    if (!savedCredentials) {
      return null;
    }
    return this._localStorage.getSidebarMinimized();
  }

//   saveCurrentLang(lang:any) {
//     const credentials = this._localStorage.getCredentials();
//     const savedCredentials = credentials ? JSON.parse(credentials) : null;
//     if (!savedCredentials) {
//       return null;
//     }

//     if (lang) {
//       savedCredentials['lang'] = lang;
//       // savedCredentials[key] = JSON.stringify(obj);
//     } else {
//       savedCredentials['lang'] = 'en';
//     }

//     this._localStorage.setCredentials(savedCredentials);
//   }

  getSavedLang() {
    return this._localStorage.getSavedLang();
  }

  ForgotPassword(email)
  {
    return  this.apiHandler
    .get(`${environment.API_URL.Accountkit.ForgotPassword}` + email)
   //return this.http
   // .post<any>(environment.API_URL.Accountkit.ForgotPassword, email)
    .pipe(
      map((response) => {
        if (response) {
          return response;
        } else {
          return;
        }
      })
    )
    .pipe(catchError(this.handleError));
  }
  private handleError(error: any) {
    return of(error.error);
  }
}
