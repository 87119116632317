<main class="container">
    <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
            <!-- @if (ViewBag.Error != null)
            {
                <div class="alert alert-danger">@Html.Raw(ViewBag.Error)</div>
            } -->

            <div class="row" *ngIf="isshowLoginMessage">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="alert alert-danger">{{showLoginMessage}} Please
                        <a class="logincolor" href="/auth/login"> LOGIN</a>
                    </div>
                </div>
            </div>
            <div class="panel panel-default" id="layoutPanel" >
                <div class="panel-heading"  id="layoutPanelHeading" >
                    <strong>Create Master User Password</strong>
                </div>
                <div class="panel-body" id="layoutPanelBody">
                    <form role="form" #form="ngForm" (ngSubmit)="save(form)">
                        <fieldset>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label for="email" class="control-label">Email</label>
                                        <input type="text"
                                            name="username"
                                            id="username"
                                            [(ngModel)]="username"
                                            disabled
                                            class="form-control" />
                                    </div>


                                    <div class="form-group">
                                        <label for="password" class="control-label">Password</label>
                                        <input type="password"
                                            name="password"
                                            id="password"
                                            [(ngModel)]="password"  
                                                onkeypress="return (((event.charCode >= 48) && (event.charCode <= 57)) || ((event.charCode >= 65) && (event.charCode <= 90)) || ((event.charCode >= 97) && (event.charCode <= 122)) || ((event.charCode >= 33) && (event.charCode <= 44)) || ((event.charCode >= 46) && (event.charCode <= 47))  || ((event.charCode >= 58) && (event.charCode <= 64)) || (event.charCode == 91) || ((event.charCode >= 93) && (event.charCode <= 95)) || (event.charCode == 123) || (event.charCode == 125) )"
                                            class="form-control"
                                            required>
                                        <div><label id="error" style="color: #a94442;padding-top: 7px;margin-bottom: 0;text-align: right;" class="control-label" *ngIf="!isPasswordValid">Invalid Password</label></div>
                                        <div><label class="control-label" style="font-size:11px;color:black;">Password should have minimum 8 and maximum 15 characters with 1 digit.</label></div>
                                        <div><label class="control-label" style="font-size:11px;color:black;">Password must have at least 1 lowercase letter.</label></div>
                                    </div>

                                    <div class="form-group">
                                        <label for="repeatPassword" class="control-label">Repeat Password</label>

                                        <input type="password"
                                            name="repeatPassword"
                                            id="repeatPassword"
                                            class="form-control"
                                            [(ngModel)]="confirmpassword"
                                            required
                                            />

                                    </div>
                                    <!-- <input type="hidden" name="Username" id="Username" value="@(Request["userName"])" /> -->
                                    <div class="form-group">
                                        <br />
                                        <input type="submit" id="btnCreatePassword" class="btn btn-lg btn-primary btn-block" value="Create Password">
                                    </div>
                                </div>
                            </div>

                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
    
</main>


