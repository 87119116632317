import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import { environment } from 'src/environments/environment';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { catchError, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { param } from 'jquery';
@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiHandler: ApiHandler,
    private http: HttpClient
  ) {}
  token: string;
  id:string;
  invite: any = {};
  isShowQRCode:boolean=false;
  ngOnInit(): void {
    this.invite = this.route.snapshot.data['data'];
    if(this.invite.InviteType=='Temporary')
    {
      this.isShowQRCode=true;
    }
    this.route.queryParams.subscribe((params: any) => {
      this.token = params.d;
      this.id = params.id;
    });
  }
// Note: QR Code now renamed as VirtualKey based on ticket(ENCGWM-9840)
  SaveCalendar(inviteID: any) {
    const url = `${environment.API_URL.VoiceVideo.url}/api/QRCode/GetCalender?`;
   
    let httpParams = new HttpParams();
    httpParams = httpParams.set('inviteId',inviteID);
    // Object.keys(params).forEach(key => {
    //   httpParams = httpParams.set(key, params[key].toString());
    // });
    var params:any = "inviteId="+inviteID;
   params = this.apiHandler.encryptParams(params,'');
    this.http
      .get(url + params,{
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response: ArrayBuffer) => {
          const blob = new Blob([response], { type: 'text/calendar' });
          saveAs(blob, 'calender.ics');
        }),
        catchError(this.handleError)
      )
      .subscribe();
  }

  SavePkPass(inviteID: any) {
    const url = `${environment.API_URL.pkPassFile.url}/api/QRCode/GetPKPass?`;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('inviteId',inviteID);
    var params:any = "inviteId="+inviteID;
   params = this.apiHandler.encryptParams(params,'');
    this.http
      .get(url + params ,{
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response: ArrayBuffer) => {
          const blob = new Blob([response], {
            type: 'application/vnd.apple.pkpass',
          });
          saveAs(blob, 'event.pkpass');
        }),
        catchError(this.handleError)
      )
      .subscribe();
  }

  AddToGoogleWallet(inviteID: any) {
    const url =
      `${environment.API_URL.VoiceVideo.url}` +
      '/api/QRCode/GetGoolgleJWT?inviteId=' +
      inviteID;
    return this.apiHandler.get(url).subscribe((url: any) => {
      window.open(url, '_blank');
    });
  }
GenerateQRCode()
{
   this.isShowQRCode=true;
}

  private handleError(error: any) {
    return of(error.error);
  }
}
