import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, of } from 'rxjs';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { NotificationService } from 'src/app/core/services/notification.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterUserService {

  constructor(private apiHandler: ApiHandler, private router: Router, private NS: NotificationService) { }

  validateMasterUser(userName: string) {
    let url = `${environment.API_URL.Accountkit.url}/api/UserDetails/createMasterUser?userName=` + userName;
    return this.apiHandler.get(url).pipe(
      map((response:any) => {
        if(response.isSuccess){
          return true;
        }
        else if(!response.isSuccess && response.message =="Password already exists"){
          return true;

        }
          else {
          let data = response.data;
          let route = data['redirect'];

          return this.getRedirectRoute(route);

        }

      })

    );
  }

  getMasterUserDetails(userName: string) {
    let url = `${environment.API_URL.Accountkit.url}/api/UserDetails/createMasterUser?userName=` + userName;
    return this.apiHandler.get(url).pipe(
      map((response:any) => response )
    );
  }
  getWatchmanSystemConfiguration() {
    let url = `${environment.API_URL.Accountkit.url}/api/AccountKit/GetWatchmanSystemConfiguration`;
    return this.apiHandler.get(url).pipe(
      map((response:any) => {
        return response;
      })

    )
  }

  getRedirectRoute(route) {
    let url = "";
    switch(route) {
      case "signin":
        url = '/auth/login';
        break;
      case "nonwebacessuser":
        url = "non";
        break;
    }

    return this.router.parseUrl(url);
  }

  createMasterUserPassword(data){
    let url = `${environment.API_URL.Accountkit.url}/api/UserDetails/createMasterUserPassword`;
    return this.apiHandler.post(url, data).pipe(
      map((response:any) => {
        // if(response.isSuccess){
        //   this.NS.notifySuccess("Password Updated");
        // }else {
        //   this.NS.notifyError("Update Failed");
        // }
        return response;
      })

    )
  }
}
