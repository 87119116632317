

<main class="container">
    <form role="form" action="#" method="POST">
        <fieldset>
            <!-- <div class="row">
                <div class="center-block">
                    <img class="logo" src="@ViewBag.bigLogoDataUrl" />
                </div>
            </div> -->
            <div class="row" >
                <div class="col-sm-6 col-sm-offset-3"> 
                    <div class="row" *ngIf="Iserrmessage">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="alert alert-danger">
                                {{ErrorMsg}}
                            </div>
                        </div>
                    </div>                   
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <strong>Create Password</strong>
                        </div>
                        <div class="panel-body">
                          <form class="form"   [formGroup]="CreatePasswordForm"  (ngSubmit)="isMatch&&ResetPassword()">
                            <fieldset>
                                <div class="row">
                                    <div class="col-sm-12" [ngStyle]="Iserrmessage ? {'margin-left':'25px'}:{'margin-left':'60px'}" style="width:max-content;">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon" style="width:170px;text-align:left">
                                                    <i class="glyphicon glyphicon-lock">
                                                        <label class="control-label" style="color:black;margin-left: 10px;"><strong>Password </strong></label>
                                                    </i>
                                                </span>
                                               <!--  <input class="form-control input-sm" name="password" ng-model="password" type="password" autofocus> -->
                                                <input type="password"
                                                       name="password"
                                                       id="password" formControlName="password"
                                                       ng-model="password" 
                                                       onkeypress="return (((event.charCode >= 48) && (event.charCode <= 57)) || ((event.charCode >= 65) && (event.charCode <= 90)) || ((event.charCode >= 97) && (event.charCode <= 122)) || ((event.charCode >= 33) && (event.charCode <= 44)) || ((event.charCode >= 46) && (event.charCode <= 47))  || ((event.charCode >= 58) && (event.charCode <= 64)) || (event.charCode == 91) || ((event.charCode >= 93) && (event.charCode <= 95)) || (event.charCode == 123) || (event.charCode == 125) )"
                                                       class="form-control"
                                                       >
                                                     
                                                <input type="hidden" name="passwordResetToken" value="@ViewBag.PasswordResetToken" />  </div>
                                        </div>
                                       
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon" style="width:170px;text-align:left">
                                                    <i class="glyphicon glyphicon-lock">
                                                        <label class="control-label" style="color:black;margin-left: 10px;"><strong>Repeat Password </strong></label>
                                                    </i>
                                                </span>
                                               <!--  <input class="form-control input-sm" name="password" ng-model="password" type="password" autofocus> -->
                                                <input type="password"
                                                       name="password"
                                                       id="conformpassword" formControlName="confirmpassword"
                                                       ng-model="password" 
                                                       onkeypress="return (((event.charCode >= 48) && (event.charCode <= 57)) || ((event.charCode >= 65) && (event.charCode <= 90)) || ((event.charCode >= 97) && (event.charCode <= 122)) || ((event.charCode >= 33) && (event.charCode <= 44)) || ((event.charCode >= 46) && (event.charCode <= 47))  || ((event.charCode >= 58) && (event.charCode <= 64)) || (event.charCode == 91) || ((event.charCode >= 93) && (event.charCode <= 95)) || (event.charCode == 123) || (event.charCode == 125) )"
                                                       class="form-control"
                                                       >
                                                     
                                                <input type="hidden" name="passwordResetToken" value="@ViewBag.PasswordResetToken" />  </div>
                                        </div>
                                        <div class="error-messages">
                                                <div class="error-message" >
                                                    <label class="control-label" *ngIf="CreatePasswordForm.get('password')?.invalid && (CreatePasswordForm.get('password')?.dirty || CreatePasswordForm.get('password')?.touched)"  style="color: #a94442;">Invalid Password</label>
                                                 </div>
                                        </div>
                                        <div class="error-messages">
                                            <div class="error-message" >
                                                <label class="control-label" *ngIf="CreatePasswordForm.get('confirmpassword')?.invalid && (CreatePasswordForm.get('confirmpassword')?.dirty || CreatePasswordForm.get('confirmpassword')?.touched)"  style="color: #a94442;">Invalid Confirm Password</label>
                                             </div>
                                           
                                        </div>
                                        
                                        <div *ngIf="isMatch==false"> <label class="control-label" style="font-size:11px;color:red;"><b>Confirm Passwords do not match..</b></label></div>
                                        <div><label class="control-label" style="font-size:11px;color:black;">Password should have minimum 8 and maximum 15 characters with 1 digit.</label></div>
                                        <div><label class="control-label" style="font-size:11px;color:black;">Password must have at least 1 lowercase letter.</label></div>
                                        <div class="form-group" *ngIf="Iserrmessage">
                                            <span class="text-danger">{{ErrorMsg}}</span>
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" [disabled]="!isMatch" class="btn btn-lg btn-primary btn-block" value="Create Password"  >
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
    
        </fieldset>
    
      
    </form>
</main>