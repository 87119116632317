import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { NotificationService } from 'src/app/core/services/notification.service';
import { castArray, List } from 'lodash';
import { throwIfAlreadyLoaded } from 'src/app/core/ensureModuleLoadedOnceGuard';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CodeValidateDto } from 'src/app/core/models/code-validate-dto';
import * as CryptoJS from 'crypto-js';
import { APP_DI_CONFIG } from 'src/app/config/app.config';
import { privateKey } from '../../../../core/services/config';
import * as Forge from 'node-forge';
export const USER = new InjectionToken("USER");
export interface PasswordChangeContext {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  UserDetailsList: any = null;
  AccountDetailsList: any[] = [];
  BasicModeDetails: boolean = false;
  CONTACT_EDIT_ID: number = 0;
  PROPERTY_CONTACT_EDIT_ID: number = 0;
  QR_ID:number=0;
  PSTNGROUP_ID:number=0;
  Callgroup_ID:number=0;
  accountId: any = 4;
  INVOICE_VIEW_ID: number = 0;
  USER_EDIT_ID: number = 0;
  PROPERTY_ID: number = 0;
  MODULE_ID: number = 0;
  MODULEPORT_ID: number = 0;
  IMAGE_ID:Number=0;
  MAIL_ID:any;
  IS_ML:boolean =false;
  ISMAIL_SEND:boolean;
  IMAGE_PROPERTY_ID: number = 0;
  IMAGE_MODULEPORT_ID: number = 0;
  constructor(
    private apiHandler: ApiHandler,
    private notificationService: NotificationService
  ) { }

  private USER = new BehaviorSubject(this.UserDetailsList);
  private ACCOUNT = new BehaviorSubject(this.UserDetailsList);
  UserDetails = this.USER.asObservable();
  AccountDetails = this.ACCOUNT.asObservable();

  private accountSource = new BehaviorSubject(false);
  public getAccountStatus = this.accountSource.asObservable();

  private watchmanSource = new BehaviorSubject(false);
  public getwatchmanStatus = this.watchmanSource.asObservable();

  private loationSource = new BehaviorSubject(0);
  public getloationVal = this.loationSource.asObservable();

  private accountLoader = new BehaviorSubject(false);
  public getaccountLoader = this.accountLoader.asObservable();
  changeLoaderStatus(status: boolean) {
    this.accountLoader.next(status);
  }

  changeLocationVal(val: any) {
    this.loationSource.next(val);
  }




  // getCallgroupUserByUserIDAndPosition(id: number){
  //   return this.apiHandler.post(`${environment.API_URL.Accountkit.getCallgroupUserByUserIDAndPosition}${id}`, null)
  //     .pipe(
  //       map(response => response)
  //     );

  // }

  isClientPhoneUnique(username) {
    let url = `${environment.API_URL.Accountkit.isClientPhoneUnique}${username}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
  isClientEmailUnique(username) {
    let url = `${environment.API_URL.Accountkit.isClientEmailUnique}${username}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
  // Note: QR Code now renamed as VirtualKey based on ticket(ENCGWM-9840)
  QRCodeIniviteUser(AccountId,UserId){
    let url=`${environment.API_URL.VoiceVideo.QRCodeInviteUser}`;
    return this.apiHandler.post(url, {
      AccountId: AccountId,
      UserId: UserId

    }).pipe(catchError(this.handleError));
  }
  DeviceValidation(cgroupId:any,callGroupName:any,selectedDevices:any,AccountID:any)
  {
    var data = {
      CallgroupId : cgroupId,
      CallgroupName: callGroupName,
      DeviceIds:selectedDevices,
      AccountId:AccountID
  };
  return this.apiHandler.post(`${environment.API_URL.Accountkit.getvideocallvalidation}`, data).pipe(map((response) => {


      if (response!=undefined)
      return response;
      })).pipe(catchError(this.handleError));
  }
  GetUserLimitation(AccountId,UserId){
   let url=`${environment.API_URL.VoiceVideo.GetUserLimitation}`;
   return this.apiHandler.get(url+AccountId+"&UserId="+UserId)
  }
  DeactivateAllUserInvitesandUpdateLimit(AccountId,UserId,Limit)
  {
    let url=`${environment.API_URL.VoiceVideo.DeactivateAllUserInvitesandUpdateLimit}`;
    return this.apiHandler.post(url, {
      AccountId: AccountId,
      UserId: UserId,
      Limit:Limit

    }).pipe(catchError(this.handleError));
  }
  GetRecurringRestrictionById(recInviteID){
    let url=`${environment.API_URL.VoiceVideo.GetRecurringRestrictionById}`;
    return this.apiHandler.post(url, {
      ID: recInviteID,
    }).pipe(catchError(this.handleError));
  }
  QRGroupAccess(AccountId,UserId){
    let url=`${environment.API_URL.VoiceVideo.QRGroupAccess}`;
   return this.apiHandler.get(url+AccountId+"&UserId="+UserId)
  }
  UpdateOrCreateUserLimitations(AccountId,UserId,Limit)
  {
    let url=`${environment.API_URL.VoiceVideo.UpdateOrCreateUserLimitations}`;
    return this.apiHandler.post(url, {
      AccountId: AccountId,
      UserId: UserId,
      Limit:Limit
  }).pipe(catchError(this.handleError));
  }
  changeAccountStatus(status: boolean) {
    this.accountSource.next(status);
  }
  changewatchmanStatus(status: boolean) {
    this.watchmanSource.next(status);
  }
  changeUserDetail(status: any) {
    status.ActuationCapableMode =
      status.LimitActuationModeID == 1 || status.LimitActuationModeID == 2
        ? status.LimitActuationModeID
        : 0;
    this.UserDetailsList = status;
    this.USER.next(status);
  }
  decryption(value: any): any {
    if(privateKey != null && privateKey != undefined){
      const rsa = Forge.pki.privateKeyFromPem(privateKey);
      var ctBytes = Forge.util.decode64(value);    
      var plaintextBytes = rsa.decrypt(ctBytes);
      return plaintextBytes.toString();
    }
  return value;
}
  loadScript(data:any) {
   if( document.getElementById('mapscript')!=null){
    var map:any=document.getElementById('mapscript');
  }else
  {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async=true;
    this.decryption(data.G_AK);
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.decryption(data.G_AK); //& needed
    script.id="mapscript";
    document.body.appendChild(script);
  }
 /*  if( document.getElementById('stripekeyscript')!=null){
    var map:any=document.getElementById('stripekeyscript');
  }else
  {
    var stripescript = document.createElement('script');
    stripescript.type = 'text/javascript';
    stripescript.async=true;
    stripescript.id="stripekeyscript";
    stripescript.setAttribute('StripeKey', data.StripePublishKey);
    document.body.appendChild(stripescript);
  } */
  }
  changeAccountDetail(status: any) {
    this.AccountDetailsList = status;
    this.ACCOUNT.next(status);
  //  this.loadScript(status);
  }
  getRolePrivilege(_roleId: any, _currentModule = '') {
    return of([{ add: 1 }, { edit: 2 }, { view: 3 }, { delete: 4 }]);
  }

  private handleError(error: any) {
    // return Observable.throw(error); // <= B
    this.notificationService.removeloading();
    return of(error.error);
  }

  // myaccount page
  getAccountContacts(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getAccountContacts}` +
        this.UserDetailsList.AccountID
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMainContacts(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getMainContacts}` +
        this.UserDetailsList.AccountID
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMapLocationUsers(accountId: number, userId: number) {
    return this.apiHandler
      .post(
        `${environment.API_URL.Accountkit.getMapLocationUsers}` +
        this.UserDetailsList.AccountID +
        '&FromAdmin=' +
        false +
        '&UserID=' +
        this.UserDetailsList.ID,
        {}
      )
      .pipe(map((response) => response));
  }

  getAccount(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getAccount}${this.UserDetailsList.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getContactTypes() {
    return this.apiHandler
      .get(`${environment.API_URL.Accountkit.getContactTypes}`)
      .pipe(map((response) => response));
  }

  getContact(id: number) {
    return this.apiHandler
      .get(`${environment.API_URL.Accountkit.getContact}${id}`)
      .pipe(map((response) => response));
  }

  getTimezone() {
    return this.apiHandler
      .get(`${environment.API_URL.Accountkit.getTimezone}`)
      .pipe(
        map((response) => {
          let TimeZones = new Array();
          _.forEach(response, (timezone: any)=> {
            TimeZones.push({
              ID: timezone.ID,
              Name: '(UTC ' + timezone.Offset + ') ' + timezone.Name,
              offset: timezone.Offset,
              Abbreviation: timezone.Abbreviation,
              Zipcodes: timezone.ZipCodes,
              IsDefaultTimezone: timezone.IsDefaultTimezone

            });
          });
          return TimeZones;
        })
      );
  }

  deleteContact(id: number) {
    return this.apiHandler
      .get(`${environment.API_URL.Accountkit.deleteContact}${id}`)
      .pipe(map((response) => response));
  }

  // getCallgroupUserByUserIDAndPosition(id: number){
  //   return this.apiHandler.post(`${environment.API_URL.Accountkit.getCallgroupUserByUserIDAndPosition}${id}`, null)
  //     .pipe(
  //       map(response => response)
  //     );

  // }

  saveOrUpdateContact(contact: any) {
    return this.apiHandler
      .post(`${environment.API_URL.Accountkit.saveOrUpdateContact}`, contact)
      .pipe(map((reponse) => reponse));
  }

  getMapLocationUser(locationId: number) {
    return this.apiHandler
      .post(`${environment.API_URL.Accountkit.getMapLocationUser}${locationId}`)
      .pipe(map((response) => response));
  }

  getExistingUser(accountId: number, mapLocationId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getExistingUser}${accountId}&MapLocationID=${mapLocationId}`
      )
      .pipe(map((response) => response));
  }

  getPropertyManagerHierarchylevel() {
    let groupName = 'PropertyManager';
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getPropertyManagerHierarchylevel}${groupName}`
      )
      .pipe(map((response) => response));
  }

  createUser(initialValues: any = undefined) {
    initialValues = initialValues || {};
    return initialValues;
  }
  createUserStream(initialValues: any = undefined) {
    initialValues = initialValues || {};
    return of(initialValues);
  }

  editMapLocationUser(locationID: number, userID: number) {
    return this.apiHandler
      .post(
        `${environment.API_URL.Accountkit.editMapLocationUser}${locationID}&UserID=${userID}`
      )
      .pipe(map((response) => response));
  }

  saveOrUpdateAccount(account: any) {
    let url: string = `${environment.API_URL.Accountkit.saveOrUpdateAccount}`;
    return this.apiHandler
      .post(url, {
        Account: account,
        AccountPropertyLevelTypeMappingId: account.PropertyLevelTypeId,
        PropertyLevelTypeId: account.PropertyLevelTypeId,
        InviteTypeId:account.InviteTypeID
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  saveUserAccount(user: any) {
    let url: string = `${environment.API_URL.Accountkit.UpdateUser}`;
    return this.apiHandler
      .post(url, user)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCallgroupUserByUserIDAndPosition(userId, position: any = undefined) {
    let url = `${environment.API_URL.Accountkit.getCallgroupUserByUserIDAndPosition}${userId}`;
    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }
  getCallgroupUserByUserID(userId) {
    let url = `${environment.API_URL.Accountkit.getCallgroupUserByUserID}${userId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
  convertWeigand2Code(weigandCode) {
    let url = `${environment.API_URL.Accountkit.convertWeigand2Code}${weigandCode}`;
    return this.apiHandler.post(url, {}).pipe(
      map((response) => {
        if (response.Data) {
          return response.Data;
        } else {
          return null;
        }
      }),
      catchError(this.handleError)
    );
  }
  getFacilityCodeFromWeigand(weigandCode) {
    let url = `${environment.API_URL.Accountkit.getFacilityCodeFromWeigand}${weigandCode}`;
    return this.apiHandler.post(url, {}).pipe(
      map((response) => {
        if (response === undefined) {
          return null;
        } else {
          return response;
        }
      }),
      catchError(this.handleError)
    );
  }
  deleteAccessCodes(userID, accountID) {
    let url = `${environment.API_URL.Accountkit.deleteAccessCodes}${userID}&accountID=${accountID}`;
    return this.apiHandler.post(url, {}).pipe(
      map((response) => {
        if (response.IsSuccess) {
          return response.Data;
        } else {
          return false;
        }
      }),
      catchError(this.handleError)
    );
  }

  deleteCallgroupUser(userId) {
    let url = `${environment.API_URL.Accountkit.deleteCallgroupUser}${userId}`;
    return this.apiHandler.post(url, {}).pipe(
      map((response) => {
        if (response) {
          this.deleteFromExridge(response);
        }
        return true;
      }),
      catchError(this.handleError)
    );
  }

  deleteFromExridge(callgroupusers) {
    if (callgroupusers && callgroupusers.length > 0) {
      var distinctCallgroup = callgroupusers;
      if (distinctCallgroup && distinctCallgroup.length > 0) {
        var callgroupID = distinctCallgroup[0].CallgroupID;
        var userID = distinctCallgroup[0].UserID;
        let url = `${environment.API_URL.DeviceSetting.deleteFromExridge}${userID}&callgroupID=${callgroupID}`;
        return this.apiHandler.get(url).pipe(catchError(this.handleError));
      }
      return of(true);
    }
    return of(false);
  }

  getUserID(userId) {
    let url = `${environment.API_URL.Accountkit.getUserID}${userId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  getMapLocationID(accountId) {
    let url = `${environment.API_URL.Accountkit.getMapLocationID}${accountId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  getPropertyLocationID(mapLocationID) {
    let url = `${environment.API_URL.Accountkit.getPropertyLocationID}${mapLocationID}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  deleteFromDevice(PropertyLocationID, CodeToDelete = '') {
    let url = `${environment.API_URL.Accountkit.getModuleFordeleteFromDevice}${PropertyLocationID}`;
    return this.apiHandler.get(url).subscribe((response) => {
      if (response) {
        _.forEach(response, (Module) => {
          var data = {
            Device: Module.DeviceSerialNumber,
          };
          if (data.Device !== null) {
            if (Module.KitHardwareTypeID === 9) {
              return this.apiHandler.get(
                environment.API_URL.DeviceSetting +
                '/api/http/XML?Device=' +
                data.Device +
                '&TypeOfXML=delete'
              ).subscribe();
            } else if (Module.KitHardwareTypeID === 79 || Module.KitHardwareTypeID === 81 ) {
              return this.apiHandler.get(
                environment.API_URL.DeviceSetting +
                'api/OmniPoint/RemoveCode?DeviceSerialNumber=' +
                data.Device +
                '&RemoveCode=' +
                CodeToDelete +
                '&ModuleID=' +
                Module.ID
              ).subscribe();
            } else if (
              Module.KitHardwareTypeID === 10 ||
              Module.KitHardwareTypeID === 15 ||
              Module.KitHardwareTypeID === 16 ||
              Module.KitHardwareTypeID === 17
            ) {
              return Module.IsEVOTelit
                ? this.apiHandler.get(
                  environment.API_URL.DeviceSetting +
                  '/api/EVOhttp/SaveAccessCodeToDevice?Device=' +
                  data.Device +
                  '&KitHardwareTypeID=' +
                  Module.KitHardwareTypeID
                ).subscribe()
                : this.apiHandler.get(
                  environment.API_URL.DeviceSetting +
                  '/api/EVOhttp/SaveAccessCodeToDevice?Device=' +
                  data.Device +
                  '&KitHardwareTypeID=' +
                  Module.KitHardwareTypeID
                ).subscribe();
            } else if (Module.KitHardwareTypeID === 13) {
              return this.apiHandler.get(
                environment.API_URL.DeviceSetting +
                '/api/U2http/SaveAccessCodeToDevice?Device=' +
                data.Device +
                '&KitHardwareTypeID=' +
                Module.KitHardwareTypeID
              ).subscribe();
            } else if (Module.KitHardwareTypeID === 14) {
              return this.apiHandler.get(
                environment.API_URL.DeviceSetting +
                '/api/TalkMan/SaveAccessCodeToDevice?Device=' +
                data.Device +
                '&KitHardwareTypeID=' +
                Module.KitHardwareTypeID
              ).subscribe();
            }
          }
          return;
        });
      }
    });
  }

  getUserGroupBasedOnName(name) {
    let url = `${environment.API_URL.Accountkit.getUserGroupBasedOnName}${name}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  viewAccessUserID(userID) {
    let url = `${environment.API_URL.Accountkit.viewAccessUserID}${userID}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  getUserGroupHierarchyLevel(UserID) {
    let url = `${environment.API_URL.Accountkit.getUserGroupHierarchyLevel}${UserID}`;
    return this.apiHandler.get(url).pipe(
      map((response) => response[0]),
      catchError(this.handleError)
    );
  }

  getUserTierUser(
    page,
    count,
    sort,
    accountID,
    viewAccessUserID,
    isAllowAccountAccess,
    loginUserHierarchyLevel,
    searchText,
    searchType,
    userId
  ) {
    let url = `${environment.API_URL.Accountkit.getUserTierUser}`;
    let data = {
      page: page,
      count: count,
      sort: sort,
      accountID: accountID,
      viewAccessUserID: viewAccessUserID,
      isAllowAccountAccess: isAllowAccountAccess,
      loginUserHierarchyLevel: loginUserHierarchyLevel,
      searchText: searchText,
      searchType: searchType,
      userId: userId,
    };

    return this.apiHandler.post(url, data).pipe(
      map((response) => response || null),
      catchError(this.handleError)
    );
  }

  getUserTypeName(userID) {
    let url = `${environment.API_URL.Accountkit.getUserTypeName}${userID}`;
    return this.apiHandler.post(url, {}).pipe(
      map((response) => response || null),
      catchError(this.handleError)
    );
  }

  getUser(userId) {
    let url = `${environment.API_URL.Accountkit.getUser}${userId}`;
    return this.apiHandler.get(url).pipe(
      map((response) => {
        if (response)
          response.WebLoginExpirationDate = response.WebLoginExpirationDate ? new Date(response.WebLoginExpirationDate) : null;
        return response || [];
      }),
      catchError(this.handleError)
    );
  }

  getUserAccount(userId) {
    let url = `${environment.API_URL.Accountkit.getUserAccount}${userId}`;
    return this.apiHandler.get(url).pipe(
      map((response) => {
        if (response)
        return response || [];
      }),
      catchError(this.handleError)
    );
  }
  setUserPassword(userId, password, username?) {
    let url = `${environment.API_URL.Accountkit.setUserPassword}`;
    if (username == undefined || username == null) {
      username = null;
    }

    var data = {
      UserID: userId,
      Username: username,
      Password: password,
    };

    let header = {
      UserID: this.UserDetailsList.ID,
    };

    return this.apiHandler
      .post(url, data, header)
      .pipe(catchError(this.handleError));
  }

  exridgeCallgroupUserUpdate(userId, callgroupId) {
    let url = `${environment.API_URL.DeviceSetting.exridgeCallgroupUserUpdate}${userId}&callgroupId=${callgroupId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
   updateUserInfo(user) {
    return this.updateUserAccount(user.ID, 0, user.Password);
}

 updateUserAccount(userID, callGroupId, password) {
let url = `${environment.API_URL.Accountkit.updateUserAccount}`;
return this.apiHandler.post(url, { ID: userID, CallGroupId: 0, Password: password }).pipe(
  map((response) => response ? response : null),
  catchError(this.handleError)
);

}
  deleteUser(userId) {
    let url = `${environment.API_URL.Accountkit.deleteUser}${userId}`;

    return this.apiHandler.post(url).pipe(
      map((response) => (response ? true : false)),
      catchError(this.handleError)
    );
  }
  getUserGroupCategoriesForAddEditUserOnly(tenantTypeID, accountID, userID) {
    if(accountID==null||accountID==undefined)
    {
      accountID = 0;
      tenantTypeID = 0;
    }
    let url = `${environment.API_URL.VoiceVideo.getUserGroupCategoriesForAddEditUserOnly}${tenantTypeID}&accountID=${accountID}&userID=${userID}`;
    return this.apiHandler.get(url).pipe(
      map((response) => response || []),
      catchError(this.handleError)
    );
  }

  isMultipleAccessCodeUniqueAtLocation(
    accessCodeList,
    propertyLocationIDList,
    accountID,
    userID,
    moduleIDsList: any = undefined
  ) {
    if (
      userID === undefined ||
      userID === null ||
      userID <= 0 ||
      userID === false
    )
      userID = 0;
    var data = {
      propertyLocationIDs: propertyLocationIDList,
      httpAccessCodeRequestDto: accessCodeList,
      accountID: accountID,
      userID: userID,
      hashKey: null,
      ModuleIDs: moduleIDsList,
    };

    let url = `${environment.API_URL.Accountkit.isMultipleAccessCodeUniqueAtLocation}`;
    return this.apiHandler.post(url, data).pipe(catchError(this.handleError));
  }

  getUnusedRfidClickersAtLocation(accountID, propertyLocationIDs, moduleIDs) {
    let data = {
      propertyLocationIDs: propertyLocationIDs,
      accountID: accountID,
      moduleIDs: moduleIDs,
    };

    let url = `${environment.API_URL.Accountkit.getUnusedRfidClickersAtLocation}`;
    return this.apiHandler.post(url, data).pipe(
      map((response) => response.Data),
      catchError(this.handleError)
    );
  }
  getAccountDetails(accountId) {
    let url = `${environment.API_URL.Accountkit.getAccountDetails}${accountId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  getWeigandPorts(accountId) {
    let url = `${environment.API_URL.Accountkit.getWeigandPorts}${accountId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
  getAccessCodeTypes() {
    let url = `${environment.API_URL.Accountkit.getAccessCodeTypes}`;
    return this.apiHandler.get(url).pipe(
      map((res) => res || []),
      catchError(this.handleError)
    );
  }

  GettierLeveAccessByAcount(accountID) {
    let url = `${environment.API_URL.Accountkit.GettierLeveAccessByAcount}${accountID}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  isClientUsernameUnique(username, id) {
    let url = `${environment.API_URL.Accountkit.isClientUsernameUnique}${username}&userID=${id}`;
    return this.apiHandler.get(url).pipe(
      map((res) => res <= 0),
      catchError(this.handleError)
    );
  }

  isUsernameUnique(username, userId = false) {
    let url = `${environment.API_URL.Accountkit.url}/api/UserDetails/isNormalUsernameUnique?username=${username}&userID=${userId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  saveUserDetailsFromUserWizard(
    userDetails,
    accessCodeDetails,
    userAccessPointsDetails
  ) {
    let data = {
      UserDetails: userDetails,
      AccessCodeDetails: accessCodeDetails,
      AccessPoints: userAccessPointsDetails,
      UserId: 0,
    };

    let url = `${environment.API_URL.Accountkit.saveUserDetailsFromUserWizard}`;
    return this.apiHandler.post(url, data).pipe(
      map((res) => res || 0),
      catchError(this.handleError)
    );
  }

  saveToDevice( PropertyLocationID, AccessCode = '', Action = '', OldAccessCode = '') {
    let url = `${environment.API_URL.Accountkit.GetModuleByPropertyLocationID}${PropertyLocationID}`;
    return firstValueFrom(this.apiHandler.get(url)).then((response) => {
      if (response) {
        _.forEach(response, (Module) => {
          var data = { Device: Module.DeviceSerialNumber, };
          if (data.Device !== null) {
            if (Module.KitHardwareTypeID === 9) {
              this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/http/XML?Device=' + data.Device + '&TypeOfXML=user') .subscribe();
            } else if ( Module.KitHardwareTypeID === 10 || Module.KitHardwareTypeID === 15 || Module.KitHardwareTypeID === 16 || Module.KitHardwareTypeID === 17) {
              Module.IsEVOTelit ? this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/EVOhttp/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + Module.KitHardwareTypeID) .subscribe() : this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/EVOhttp/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + Module.KitHardwareTypeID).subscribe();
            } else if (Module.KitHardwareTypeID === 13) {
              this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/U2http/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + Module.KitHardwareTypeID).subscribe();
            } else if (Module.KitHardwareTypeID === 14) {
              this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/TalkMan/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + Module.KitHardwareTypeID).subscribe();
            } else if (Module.KitHardwareTypeID === 79 || Module.KitHardwareTypeID === 81 ) {
              if (Action == 'Edit') {
                this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/OmniPoint/UpdateCode?DeviceSerialNumber=' + data.Device + '&NewCode=' + AccessCode + '&RemoveCode=' + OldAccessCode + '&ModuleID=' + Module.ID).subscribe();
              }
              if (Action == 'Add') {
                this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/OmniPoint/AddCode?DeviceSerialNumber=' + data.Device + '&AddCode=' + AccessCode + '&ModuleID=' + Module.ID).subscribe();
              }
            }
          }
        });
      }
    });
  }

  getModulesByPortIDList(portIDList) {
    let url = `${environment.API_URL.Accountkit.getModulesByPortIDList}${portIDList}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  saveToDeviceByDeviceSerialNumber(
    DeviceSerialNumber,
    KitHardwareTypeID,
    IsEVOTelit
  ) {
    var data = {
      Device: DeviceSerialNumber,
    };

    if (data.Device !== null) {
      if (KitHardwareTypeID === 9) {
        this.apiHandler.get( environment.API_URL.DeviceSetting.url + '/api/http/XML?Device=' + data.Device + '&TypeOfXML=user') .subscribe();
      } else if ( KitHardwareTypeID === 10 || KitHardwareTypeID === 15 || KitHardwareTypeID === 17) {
        IsEVOTelit ? this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/EVOhttp/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + KitHardwareTypeID) .subscribe() : this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/EVOhttp/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + KitHardwareTypeID) .subscribe();
      } else if (KitHardwareTypeID === 13) {
        this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/U2http/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + KitHardwareTypeID) .subscribe();
      } else if (KitHardwareTypeID === 14) {
        this.apiHandler .get( environment.API_URL.DeviceSetting.url + '/api/TalkMan/SaveAccessCodeToDevice?Device=' + data.Device + '&KitHardwareTypeID=' + KitHardwareTypeID) .subscribe();
      }
    }
  }

  updateAccessCodeRangeMaplocationMappings(
    code,
    propertyLocationIDs,
    accountID
  ) {
    let url = `${environment.API_URL.Accountkit.updateAccessCodeRangeMaplocationMappings}`;
    let data = {
      propertyLocationIDs: propertyLocationIDs,
      code: code,
      accountID: accountID,
    };

    return this.apiHandler.post(url, data)
    .pipe(map(response => {
        return response || [];
    }));
  }

  assignCallgroupToCallgroupManagerAndSendEmail(userId, callgroupId) {
    let url = `${environment.API_URL.Accountkit.assignCallgroupToCallgroupManagerAndSendEmail}${userId}&callgroupID=${callgroupId}`;

    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }

  saveDeviceTOCallgroup(userID, callgroupID, accountID) {
    let url = `${environment.API_URL.Accountkit.saveDeviceTOCallgroup}${userID}&CallgroupID=${callgroupID}&AccountID=${accountID}`;
    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }

  sentInviteCallgroupManager(
    email,
    phone,
    alternativePhone,
    userID,
    callgroupID,
    isNotSendEmail
  ) {
    if (phone === '') {
      phone = null;
    }
    if (isNotSendEmail == null || isNotSendEmail == undefined) {
    			isNotSendEmail = false
  			}
    let url = `${environment.API_URL.Accountkit.sentInviteCallgroupManager}${email}&Phone=${phone}&AlternativePhone=${alternativePhone}&UserID=${userID}&CallgroupID=${callgroupID}&isNotSendEmail=${isNotSendEmail}`;

    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }

  saveMasterUserToVoiceCallgroup(
    userID,
    callgroupID,
    accountID,
    isNewCallgroup,
    pstnGroupID,
    devicePassword,
    customerName,
    customerFirstName,
    customerLastName
  ) {
    let url = `${environment.API_URL.Accountkit.saveMasterUserToVoiceCallgroup}${userID}&callgroupID=${callgroupID}&accountID=${accountID}&isNewCallgroup=${isNewCallgroup}&pstnGroupID=${pstnGroupID}&devicePassword=${devicePassword}&customerName=${customerName}&customerFirstName=${customerFirstName}&customerLastName=${customerLastName}`;
    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }
  isAccessCodeUniqueWithWiegand(accountID, accessCode, wiegandCode){
    let url = `${environment.API_URL.Accountkit.isAccessCodeUniqueWithWiegand}${accountID}&accessCode=${accessCode}&wiegandCode=${wiegandCode}`;
    return firstValueFrom(this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    ));
  }
  isAccessCodeUniqueAtLocation(
    accessCode,
    accessCodeType,
    propertyLocationIDs,
    userID,
    accountID,
    hashKey?,
    accesscodeID?,
    moduleIDList?
  ):Observable<CodeValidateDto> {
    if (userID === undefined || userID === null) userID = 0;
    if (hashKey === undefined || hashKey === null) hashKey = '';
    if (accesscodeID === undefined || accesscodeID === null) accesscodeID = 0;

    let data = {
      code: accessCode,
      accessCodeTypeID: accessCodeType,
      propertyLocationIDs: propertyLocationIDs,
      userID: userID,
      accountID: accountID,
      hashKey: hashKey,
      accessCodeID: accesscodeID,
      moduleIDs: moduleIDList,
    };
    let url = `${environment.API_URL.Accountkit.isAccessCodeUniqueAtLocation}`;

    return this.apiHandler.post(url, data).pipe(
      map((res: any) => res.Data),
      catchError(this.handleError)
    );
  }
  isWiegandCodeUniqueAtLocation(weigandCode, accessCodeType, propertyLocationIDs, userID, accountID) {
    if (userID === undefined || userID === null)
    userID = 0;
  if (weigandCode === undefined || weigandCode === null)
    weigandCode = "";
    let data = {
      code: "",
      accessCodeTypeID: accessCodeType,
      propertyLocationIDs: propertyLocationIDs,
      userID: userID,
      accountID: accountID,
      hashKey: weigandCode
    };
    let url = `${environment.API_URL.Accountkit.isWiegandCodeUniqueAtLocation}`;
    return this.apiHandler.post(url, data).pipe(
      map((res: any) => !res.Data),
      catchError(this.handleError)
    );
  }

  generatedAndValidatedWeigandCode(
    code,
    facilityCode,
    codeType,
    propertyLocationList,
    accountID,
    userID,
    accesscodeID,
    moduleIDList
  ) {
    if (userID === undefined || userID === null) userID = 0;
    if (facilityCode === undefined || facilityCode === null) facilityCode = 1;
    if (accesscodeID === undefined || accesscodeID === null) accesscodeID = 0;

    let data = {
      code: code,
      facilityCode: facilityCode,
      accountID: accountID,
      propertyLocationIDs: propertyLocationList,
      accessCodeTypeID: codeType,
      userID: userID,
      accessCodeID: accesscodeID,
      moduleIDs: moduleIDList,
    };
    let url = `${environment.API_URL.Accountkit.generatedAndValidatedWeigandCode}`;
    return this.apiHandler.post(url, data).pipe(
      map((res: any) => res.Data),
      catchError(this.handleError)
    );
  }

  isValidPhoneNumber(phone) {
    let url = `${environment.API_URL.Accountkit.isValidPhoneNumber}${phone}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  checkCallGroupNameUniqueExisted(accountId, callGroupName) {
    const encodedCallGroupName = encodeURIComponent(callGroupName);
    let url = `${environment.API_URL.Accountkit.checkCallGroupNameUniqueExisted
      }${accountId}&callGroupName=${encodedCallGroupName}&ID=${null}`;
    return this.apiHandler.get(url).pipe(
      map((res: any) => {
        if (
          !callGroupName ||
          callGroupName.length < 5 ||
          callGroupName.length > 15
        ) {
          res = 1;
        }
        return res <= 0;
      }),
      catchError(this.handleError)
    );
  }

  checkCallGroupdirectoryPINUniqueExisted(accountId: any, directoryPin: any) {

    if (directoryPin != null && directoryPin != undefined && directoryPin != "") {
      if (parseInt(directoryPin)) {
        this.notificationService.addloading();
      let url = `${environment.API_URL.VoiceVideo.CheckDirectoryPINUniqueExisted
      }${accountId}&DirectoryPIN=${directoryPin}&ID=${null}`;
      return this.apiHandler.get(url).pipe(
        map((res: any) => {
          this.notificationService.removeloading();
          if (!directoryPin || (directoryPin.length > 5)) {
            res = 1;
          }
          return res <= 0;
          // return res <= 0;
        }),
        catchError(this.handleError)
        );
      }
      else
      return of(true);
    }
      this.notificationService.removeloading();
      return of(true);
  }

  getCallgroupListForNewUser(
    accountId,
    hasAllLocationAccess,
    propertyLocations,
    modules: any = null,
    ForCallgroupUser: boolean = false
  ) {
    if (modules == null || modules == undefined) {
      modules = [];
    }
    var data = {
      AccountID: accountId,
      HasAllLocationAccess: hasAllLocationAccess,
      PropertyLocations: propertyLocations,
      Modules: modules,
      ForCallgroupUser: ForCallgroupUser
    };
    let url = `${environment.API_URL.Accountkit.getCallgroupListForNewUser}`;

    return this.apiHandler.post(url, data).pipe(
      map((res: any) => (res ? res : null)),
      catchError(this.handleError)
    );
  }

  updateWebAccessInUserEditPage(user) {
    var info = {
      ID: user.ID,
      IsWebLoginEnabled: user.IsWebLoginEnabled,
      IsMobileOnly: user.IsMobileOnly,
      IsCellgateApp: user.IsCellgateApp,
      UserTypeID: user.UserTypeID,
      WebLoginExpirationDate: user.WebLoginExpirationDate,
      WebLoginExpirationTime: user.WebLoginExpirationTime,
      IsUserAdmin: user.IsUserAdmin,
      IsKitsAdmin: user.IsKitsAdmin,
      IsBillingAdmin: user.IsBillingAdmin,
      LimitActuationModeID: user.LimitActuationModeID,
      Password: user.Password,
      Username: user.Username,
      ValidFrom: user.validFrom,
			ValidFromTime: user.validFromTime
    };
    let url = `${environment.API_URL.Accountkit.updateWebAccessInUserEditPage}`;

    return this.apiHandler.post(url, info).pipe(catchError(this.handleError));
  }
  updateUserDetailInUserEditPage(user) {
    var info = {
      ID: user.ID,
      Name: user.Name,
      Email: user.Email,
      WatchmanPhoneNumber: user.WatchmanPhoneNumber,
      TimezoneID: user.TimezoneID,
      Notes: user.Notes,
      FirstName: user.FirstName,
      LastName: user.LastName,
      Unit: user.Unit
    };

    let url = `${environment.API_URL.Accountkit.updateUserDetailInUserEditPage}`;

    return this.apiHandler.post(url, info).pipe(catchError(this.handleError));
  }
  getUsers(accountID, page, count, sort, searchText, searchType) {
    var data = {
        page: page,
				count: count,
				sort: sort,
				accountID: accountID,
				searchText: searchText,
				searchType: searchType
    };
    let url = `${environment.API_URL.Accountkit.getUserDetailsForNormalUserClientPortal}`;
    return this.apiHandler.post(url, data).pipe(catchError(this.handleError));
  }

  isAccessPhoneUnique(accessPhone) {
    let url = `${environment.API_URL.Accountkit.isAccessPhoneUnique}${accessPhone}`;

    return this.apiHandler.get(url).pipe(
      map((res) => res <= 0),
      catchError(this.handleError)
    );
  }

  isAccessCodeUnique(accountID, accessCode) {
    let url = `${environment.API_URL.Accountkit.isAccessCodeUnique}${accountID}&accessCode=${accessCode}`;

    return this.apiHandler.get(url).pipe(
      map((res) => res <= 0),
      catchError(this.handleError)
    );
  }
  isAccessPhoneCodeUnique(accessPhoneCode) {
    let url = `${environment.API_URL.Accountkit.isAccessPhoneCodeUnique}${accessPhoneCode}`;

    return this.apiHandler.get(url).pipe(
      map((res) => res <= 0),
      catchError(this.handleError)
    );
  }

  getAssignedUserGroup(userID) {
    let url = `${environment.API_URL.Accountkit.getAssignedUserGroup}${userID}`;
    return this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    );


  }

  getVoiceCallgroupForMasterUserID(MasterUserID) {
    let url = `${environment.API_URL.Accountkit.getVoiceCallgroupForMasterUserID}${MasterUserID}`;

    return this.apiHandler.post(url, {}).pipe(
      map(res => res ? res : null),
      catchError(this.handleError)
    );

  }
  getUserGroupProfileMapping(userID) {
    let url = `${environment.API_URL.Accountkit.getUserGroupProfileMapping}${userID}`;

    return this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    );
  }
  sendEmailAsForgetPasswordLink(userID) {
    let url = `${environment.API_URL.Accountkit.sendEmailAsForgetPasswordLink}${userID}`;

    return this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    );

  }

  getProperty(modulePortID) {
    let url = `${environment.API_URL.Accountkit.getProperty}${modulePortID}`;

    return this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    );

  }
  saveToOmniPointDevice(DeviceSerialNumber, AccessCode, Moduleid) {
    let url = `${environment.API_URL.DeviceSetting.saveToOmniPointDevice}${DeviceSerialNumber}&AddCode=${AccessCode}&ModuleID=${Moduleid}`;
    return firstValueFrom(this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    ));

  }

  updateToOmniPointDevice(DeviceSerialNumber, OldAccessCode, NewAccessCode, Moduleid) {

    var Data = {
      deviceserialnumber: DeviceSerialNumber,
      oldcode: OldAccessCode,
      newcode: NewAccessCode
    };
    let url = `${environment.API_URL.DeviceSetting.updateToOmniPointDevice}${Data.deviceserialnumber}&RemoveCode=${Data.oldcode}&NewCode=${Data.newcode}&ModuleID=${Moduleid}`;
    return firstValueFrom(this.apiHandler.get(url).pipe(
      catchError(this.handleError)
    ));

  }

  getCallgroupForMasterUserID(MasterUserID) {
    let url = `${environment.API_URL.Accountkit.getCallgroupForMasterUserID}${MasterUserID}`;
    return this.apiHandler.post(url, {}).pipe(
      map(res => res ? res[0] : null),
      catchError(this.handleError)
    );


  }
  getFirstCallgroupForUser(userId) {
    let url = `${environment.API_URL.Accountkit.getFirstCallgroupForUser}${userId}`;

    return this.apiHandler.get(url).pipe(
      map(res => res === -1 ? null : res),
      catchError(this.handleError)
    );

  }

  getPropertyMapLocations(accountId) {
    var isFromUserPortal = true;
    var loginUserID = this.UserDetailsList.ID;
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/PropertiesLocation/GetPropertyMaplocationsByAccountID?accountID=' + accountId + '&isFromUser=' + isFromUserPortal + '&userID=' + loginUserID).pipe(map(response => {
      return response ? response : [];
    }), catchError(this.handleError));
  }

  getDefaultProfiles(UserGroupID, HierarchyLevel) {

    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/UserTiers/GetDefaultProfiles?hierarchyLevel=" + HierarchyLevel).pipe(map((response) => {
      var results: any = response;
      var userProfiles: any = [];

      results.forEach(function (result) {
        userProfiles.push({ UserProfile: result.UserProfile, HierarchyLevel: result.HierarchyLevel });
      });
      return userProfiles;
    }), catchError(this.handleError));
  }

  migrateAccessCode(accesscodes, fromUserID, toUserID) {
    var data = {
      accessCodes: accesscodes,
      fromUserID: fromUserID,
      toUserID: toUserID
    };
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/migrateAccessCodes", data).pipe(map((response) => {
      return response;
    }), catchError(this.handleError));
  }

  CreateUpdateAccess(accesscode) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/CreateUpdateAccess", accesscode).pipe(map((response) => {
      return response;
    }), catchError(this.handleError));
  }

  getCallgroupForIDFromEditByMasterUserID(MasterUserID) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + '/api/VideoCallgroup/getCallgroupForIDFromEditByMasterUserID?masterUserID=' + MasterUserID, {}).pipe(map(response => {
      return response ? response : null;
    }, catchError(this.handleError)));

  }

  createAccessCode(initialValues = undefined) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + '/api/AccountKit/createAccessCode', {})
      .pipe(map((response) => {
        return response || [];
      }), catchError(this.handleError));
  }


  deleteAccessCodeFromUserEdit(accountID, hashKey, accessCodeTypeID, userID) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/DeleteAccessCodeFromUserEdit?accountID=" + accountID + "&hashKey=" + hashKey + "&accessCodeTypeID=" + accessCodeTypeID + "&userID=" + userID, {}).pipe(map((response => {
      if (response.IsSuccess) {
        return response.Data;
      }
      else {
        return null;
      }
    })), catchError(this.handleError));
  }

  getMapPropertyLocationForUserPortal(accountID, loginUserID) {

    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/AccountDetail/GetMapPropertyLocationForUserPortal?accountID=' + accountID + '&loginUserID=' + loginUserID).pipe(map((response) => {
      return response;
    }), catchError(this.handleError));
  }

  getPropertyMapLocationsOfAccount(accountId) {
    var isFromUserPortal = true;
    var loginUserID = this.UserDetailsList.ID;
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/PropertiesLocation/GetPropertyMaplocationsByAccountID?accountID=' + accountId + '&isFromUser=' + isFromUserPortal + '&userID=' + loginUserID).pipe(map(response => {
      return response ? response : [];
    }), catchError(this.handleError));
  }

  UpdateAccessCodeForID(ID, accessCode, isDeleted) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + '/api/useraccesscode/UpdateAccessCodeForID?ID=' + ID + '&accessCode=' + accessCode + '&isDeleted=' + isDeleted, {})
      .pipe(map((response) => {
        return response;
      }), catchError(this.handleError));
  }

  assignCallgroupToVoiceCallgroupManagerAndSendEmailAsync(userId, callgroupId) {

    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/VideoCallgroup/AssignCallgroupToVoiceCallgroupManagerAndSendEmailAsync?userID=" + userId + "&callgroupID=" + callgroupId, {}).pipe(
      catchError(this.handleError)
    );
  }
  changeGroupProfileBasedOnMasterUser(masterUserID) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/UserTiers/changeGroupProfileBasedOnMasterUser?masterUserID=" + masterUserID).pipe(
      catchError(this.handleError)
    );
  }

  getCallgroupForIDFromEditByCallgroupID(callgroupID) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/VideoCallgroup/getCallgroupForIDFromEditByCallgroupID?callgroupID=' + callgroupID).pipe(map(response => {
      return response ? response : null;
    }), catchError(this.handleError));

  }

  SaveUpdateaccessRestrictions(data) {
    var userData = {
      ID: data.ID,
      AccountID: data.AccountID,
      LimitEntryExpirationStartDate: data.LimitEntryExpirationStartDate,
      LimitEntryExpirationStartTime: data.LimitEntryExpirationStartTime,
      LimitEntryExpirationEndDate: data.LimitEntryExpirationEndDate,
      LimitEntryExpirationEndTime: data.LimitEntryExpirationEndTime,
      LimitEntryUses: data.LimitEntryUses,
      LimitEntryStartTime: data.LimitEntryStartTime,
      LimitEntryEndTime: data.LimitEntryEndTime,
      LimitUseToSunday: data.LimitUseToSunday,
      LimitUseToMonday: data.LimitUseToMonday,
      LimitUseToTuesday: data.LimitUseToTuesday,
      LimitUseToWednesday: data.LimitUseToWednesday,
      LimitUseToThursday: data.LimitUseToThursday,
      LimitUseToFriday: data.LimitUseToFriday,
      LimitUseToSaturday: data.LimitUseToSaturday
    };
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/SaveUpdateaccessRestrictions", userData).pipe(map(response => {
      return response;
    }), catchError(this.handleError));
  }

  getUserPermissionDescription(groupID) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/UserTiers/getUserPermissionDescription?groupID=" + groupID).pipe(catchError(this.handleError));
  }

  getUsersForMigration(loginUserID, accountID, viewUserAccess, isAllowAccountAccess, loginUserHierarchyLevel) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/accesscode/GetUsersForMigration", {
      loginUserID: loginUserID,
      accountID: accountID,
      viewUserAccess: viewUserAccess,
      isAllowAccountAccess: isAllowAccountAccess,
      loginUserHierarchyLevel: loginUserHierarchyLevel
    })
      .pipe(map(response => {
        if (!response.IsSuccess) {
          return null;
        } else {
          return response.Data;
        }
      }), catchError(this.handleError));
  }

  postallrestrictedcodesave(AccountID) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/useraccesscode/GetUnRestrictedCodes?AccountID=" + AccountID).pipe(map(response => {
      if (response != null && response != undefined && response > 0) {
        return response;
      } else {
        return 0;
      }
    }), catchError(this.handleError));
  }

  getPropertyLocationIDs(accountID, userID) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/GetPropertyLocationIDs?AccountID=" + accountID + "&UserID=" + userID, {})
      .pipe(map((response) => {
        var data = response ? response : null;
        var result: any = null;
        if (data !== null) {
          result = [];
          data.forEach(function (d) {
            result.push(d.ID);
          });
        }
        return result;
      }), catchError(this.handleError));
  }
  getAllPropertyLocation(accountID, propertyLocationList) {
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/UserTiers/GetAllPropertyLocation", {
      AccountID: accountID,
      PropertyLocationID: propertyLocationList
    }).pipe(catchError(this.handleError));
  }

  voiceCallgroupModelList(accountId, userId, groupID) {

    if (groupID == null || groupID == undefined) { groupID = 0; }

    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/VideoCallgroup/voiceCallgroupModelList?accountID=" + accountId + "&userID=" + userId + "&userGroupID=" + groupID).pipe(map((response) => {
      var data = response ? response : null;
      return data;
    }), catchError(this.handleError));

  }

  callgroupModelList(accountId, userId, groupID) {
    if (groupID == null || groupID == undefined) { groupID = 0; }
    return this.apiHandler.get(environment.API_URL.Accountkit.url + "/api/VideoCallgroup/callgroupModelList?accountID=" + accountId + "&userID=" + userId + "&userGroupID=" + groupID).pipe(map((response) => {
      var data = response ? response : null;
      return data;
    }), catchError(this.handleError));

  }

  getHiddenUserGroupInformation(tenantTypeID, accountID, userID) {
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetHiddenUserGroupInformation?tenantTypeID=' + tenantTypeID + '&accountID=' + accountID + '&userID=' + userID).pipe(map(result => {
      return result === null ? null : result;
    }), catchError(this.handleError));
  }

  getNonAccessUserGroupForLoginUser(tenantTypeID, accountID, userID) {
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetNonAccessUserGroupForLoginUser?tenantTypeID=' + tenantTypeID + '&accountID=' + accountID + '&userID=' + userID).pipe(map(result => {
      return result === null ? null : result;
    }), catchError(this.handleError));
  }

  getUserGroups(hierarchyId, accountId, userId) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/UserTiers/getUserGroups?hierarchyId=' + hierarchyId + '&accountId=' + accountId + '&userId=' + userId).pipe(map(response => {
      ///  return result.data === null ? null : result.data;
      var userGroup: any = [];
      if (response !== null || response !== undefined) {
        response.forEach(function (result) {
          var data = { ID: result.ID, Name: result.Name, Description: result.Description, HierarchyLevel: result.HierarchyLevel, IsClientPortalVisible: result.IsClientPortalVisible };
          userGroup.push(data);
        });
        return userGroup;
      } else {
        return null;
      }
    }), catchError(this.handleError));
  }

  getUserGroupUserProfiles() {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/UserTiers/getUserGroupUserProfiles').pipe(map(result => {
      return result.data === null ? null : result.data;
    }), catchError(this.handleError));
  }

  getPropertyInstallLocationDeviceInformation(accountID) {
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetPropertyInstallLocationDeviceInformation?accountID=' + accountID + '&isfromUser=' + false + '&loginUserID=' + 0).pipe(map(result => {
      return result.data === null ? [] : result.data;
    }), catchError(this.handleError));
  }

  saveUserGroupMultiUserMultiProfile(userGroupId, selectedUsers) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + "/api/Group/saveUserGroupMultiUserMultiProfile", {
      SelectedUsers: selectedUsers,
      SelectedUserGroupId: userGroupId
    }).pipe(map(result => {
      return result.data === null ? {} : result.data;
    }), catchError(this.handleError));
  }

  checkHoldOpenCodeForModule(propertyLocationIdList, userID, moduleIdList) {
    var data = {
      PropertyLocationID: propertyLocationIdList,
      UserID: userID,
      ModuleID: moduleIdList
    };
    return this.apiHandler.post(environment.API_URL.Accountkit.url + '/api/UserDetails/CheckHoldOpenCodeForModule', data).pipe(map((response) => {
      return response;
    }), catchError(this.handleError));
  }

  SaveUpdateAccessCodeUnique(data) {
    if (data.userID === undefined || data.userID === null || data.userID <= 0)
      data.userID = null;
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/SaveUpdateAccessCodeUnique", data).pipe(catchError(this.handleError));
  }

  getGroupRestrictionForModulePort(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetGroupRestrictionForModulePort', data).pipe(map(result => {
      return result.data === null ? {} : result.data;
    }), catchError(this.handleError));
  }

  SaveRestriction(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + '/api/NewGroup/SaveUpdateRestriction', data)
      .pipe(map((response) => {
        return response || null;
      }), catchError(this.handleError));
  }

  getGroupRestrictionForModule(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetGroupRestrictionForModule').pipe(map(result => {
      return result.data === null ? {} : result.data;
    }), catchError(this.handleError));
  }

  SaveUpdateAccessCode(data) {
    if (data.userID === undefined || data.userID === null || data.userID <= 0)
      data.userID = null;
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/useraccesscode/SaveUpdateAccessCode", data);
  }

  saveUserGroupUserProfiles(userID, userGroupID, assignedUserProfiles, isCodeuserGroupchanges = false) {
    let data = {
      UserID: userID,
      SelectedUserGroupID: userGroupID,
      SelectedUserProfiles: assignedUserProfiles,
      IsCodeuserGroupchanges: isCodeuserGroupchanges
    };
    return firstValueFrom(this.apiHandler.postForImport(environment.API_URL.Accountkit.url + "/api/UserTiers/saveUserGroupUserProfiles", data, {observe:'response'}));
    // return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/UserTiers/saveUserGroupUserProfiles", data ).pipe(
    //   map((x) =>
    //   {
    //     return  x;
    //   }
    //   ),catchError(this.handleError));
  }

  getAppliedGroupRestriction(groupId, accountId) {

    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetAppliedGroupRestriction?accountId=' + accountId + '&groupID=' + groupId).pipe(map(result => {
      return result === null ? [] : result;
    }), catchError(this.handleError));
  }

  saveGroupOrUserAccessPoints(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/SaveAccessPoints', data)
      .pipe(map((response) => {
        return response || null;
      }), catchError(this.handleError));
  }

  getGroupRestrictionForPropertyLocation(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/GetGroupRestrictionForPropertyLocation', data).pipe(map(result => {
      return result === null ? {} : result;
    }), catchError(this.handleError));
  }

  getPresetTemplateDetails(Id, accountId) {
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/getPresetTemplateDetails?Id=' + Id + '&accountId=' + accountId).pipe(map(result => {
      return result === null ? {} : result;
    }), catchError(this.handleError));
  }
  deleteUserGroupRestrictions(data) {
    return this.apiHandler.post(environment.API_URL.VoiceVideo.url + "/api/UserGroupRestriction/DeleteUserGroupRestriction", data).pipe(map(response => {
      return response;
    }), catchError(this.handleError));
  }

  getPresetRestrictionTemplates(accountId) {
    if (accountId == null || accountId == undefined)
    accountId = 0;
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/UserGroupRestriction/getPresetRestrictionTemplate?accountId=' + accountId).pipe(map(result => {
      return result === null ? {} : result;
    }), catchError(this.handleError));
  }

  getvoiceCallgroupListForNewUser(accountId, hasAllLocationAccess, propertyLocations, modules: any = undefined, ForCallgroupUser:boolean = false) {
    if (modules == null || modules == undefined) {
      modules = [];
    }
    var data =
    {
      AccountID: accountId,
      HasAllLocationAccess: hasAllLocationAccess,
      PropertyLocations: propertyLocations,
      Modules: modules,
      ForCallgroupUser: ForCallgroupUser
    };
    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/VoiceCallgroup/GetvoiceCallgroupListForNewUser", data).pipe(map((response) => {
      var data = response ? response : null;
      return data;
    }), catchError(this.handleError));

  }

  GetTierServiceforUserModuleLevel(userId, accountID) {
    return this.apiHandler.get(environment.API_URL.Accountkit.url + '/api/UserTiers/GetTierServiceforUserModuleLevel?userId=' + userId + '&accountId=' + accountID).pipe(map((response) => {
      return response;
    }), catchError(this.handleError));
  }


  //getPropertyLocationIDs(accountID, userID) {
  //  let url = `${environment.API_URL.Accountkit.getPropertyLocationIDs}${accountID}&UserID=${userID}`;
  //  return this.apiHandler.post(url)
  //    .pipe(
  //      map((response) => {
  //        var data = response ? response : null;
  //        var result: any = null;
  //        if (data !== null) {
  //          result = [];
  //          data.forEach(function (d) {
  //            result.push(d.ID);
  //          });
  //        }
  //        return result;
  //      }), catchError(this.handleError));
  //}
// Note: QR Code now renamed as VirtualKey based on ticket(ENCGWM-9840)
  checkAccountHaveQRCodeAccess(accountID: any) {
    let url = `${environment.API_URL.VoiceVideo.checkAccountHaveQRCodeAccess}${accountID}`;
    return this.apiHandler
      .get(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return {};
          }
        }));
  }

  getProperties(accountID: any) {
    let url = `${environment.API_URL.Accountkit.getPropertiesByAccountID}${accountID}`;
    return this.apiHandler
      .get(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          else {
            return null;
          }
        }), catchError(this.handleError)
      );
  }

  getGetAccBillingInfo(accountID: any) {
    let url = `${environment.API_URL.Accountkit.getGetAccBillingInfo}${accountID}`;
    return this.apiHandler
      .get(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          else {
            return null;
          }
        }), catchError(this.handleError)
      );
  }

 GetCardInfoForAccount(accountID: any) {
    let url = `${environment.API_URL.Accountkit.GetCardInfoForAccount}${accountID}`;
    return this.apiHandler
      .get(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          else {
            return null;
          }
        }), catchError(this.handleError)
      );
  }


  saveCarddetails(data) {

    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/Billing/SaveCard", data).pipe(map((response) => {
        var data = response ? response : null;
        return data;
    }),
    catchError(this.handleError)
    );
}
  assignFirstCallgroupUserAndSendEmailAsync(userId, callgroupId) {

    return this.apiHandler.post(environment.API_URL.Accountkit.url + "/api/VideoCallgroup/AssignFirstCallgroupUserAndSendEmailAsync?userID=" + userId + "&callgroupID=" + callgroupId, {});
  }

    assignFirstPstnToVoiceCallgroupAndSendEmailAsync(
    userID,
    callgroupID,
    accountID,
    isNewCallgroup,
    pstnGroupID,
    devicePassword,
    customerName,
    customerFirstName,
    customerLastName
  ) {
    let url = `${environment.API_URL.Accountkit.url}/api/VideoCallgroup/AssignFirstPstnToVoiceCallgroupAndSendEmailAsync?userID=${userID}&callgroupID=${callgroupID}&accountID=${accountID}&isNewCallgroup=${isNewCallgroup}&pstnGroupID=${pstnGroupID}&devicePassword=${devicePassword}&customerName=${customerName}&customerFirstName=${customerFirstName}&customerLastName=${customerLastName}`;
    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }

 GetFireAlarmStatus(){
  return this.apiHandler.get(environment.API_URL.VoiceVideo.url + '/api/FireAlarm/Status?userID=' + this.UserDetailsList.ID)
  .pipe(map((response) => { return response }));
 }
  ValidateGroupNotificationPermission(request:any){
    return this.apiHandler.post(environment.API_URL.VoiceVideo.ValidateGroupNotificationPermission, request)
      .pipe(
        map((x:any) => {
          return x;
        }),
        catchError(this.handleError)
        );
  }
 ValidateVoiceCallgroupNotificationPermission(request:any){
    return this.apiHandler.post(environment.API_URL.VoiceVideo.ValidateVoiceCallgroupNotificationPermission, request)
      .pipe(
        map((x:any) => {
          return x;
        }),
        catchError(this.handleError)
        );
  }
 ValidateVideoCallgroupNotificationPermission(request:any){
    return this.apiHandler.post(environment.API_URL.VoiceVideo.ValidateVideoCallgroupNotificationPermission, request)
      .pipe(
        map((x:any) => {
          return x;
        }),
        catchError(this.handleError)
        );
  }
  checkBluetoothAccess(accountId: any) {
    return this.apiHandler
      .get(`${environment.API_URL.VoiceVideo.checkBluetoothAccess}${accountId}`)
      .pipe(map((response) => response));
  }
  DeleteUserAccount(request:any){
    return this.apiHandler.post(environment.API_URL.VoiceVideo.DeleteUserAccount, request)
      .pipe(
        map((x:any) => {
          return x;
        }),
        catchError(this.handleError)
        );
  }

  GetImportValidatorUser(userID){
    return this.apiHandler.get(environment.API_URL.VoiceVideo.url+'/api/ImportValidatorUsers/GetImportValidatorUsersForAccount?userID=' + userID)
    .pipe(map((response) => { return response }));
   }
}
