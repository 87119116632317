import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/shared/services/http';

import { APP_CONFIG, IAppConfig } from '../../config/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  IsWTMConfigVideo:any=false;
  constructor( 
    public router: Router,private userService: UserService,
    @Inject(APP_CONFIG) public config: IAppConfig) {
      this.userService.getwatchmanStatus.subscribe(mode => {
        this.IsWTMConfigVideo = mode;
    });
      }

  ngOnInit() {
 
  }

  
 
}
