<nav class="navbar" role="navigation">          
    <div id="nav-offer">
        <div class="container" style="padding:0px;font-size:14px;" *ngIf="IsWTMConfigVideo">
            <i class="fa fa-phone-square fa-phone-square-size" style="margin-left: 10%;"></i>&nbsp;
            <span class="login-text">(855)-694-2837</span>  
            <div class="collapse navbar-collapse" id="navbar-top" style="float:right;margin-right: 15%;">
                <ul class="nav navbar-nav"><li style="background-color: #D1AD1F; display: inline-block;max-height: 35px;"><a style="padding-top: 11px;line-height: 9px;color: #000;" [routerLink]="['/auth/login']" target="_top">Login</a></li> </ul>
            </div>
        </div>
        <div class="container" *ngIf="!IsWTMConfigVideo">
            <i class="fa fa-phone-square fa-phone-square-size" style="margin-left: 10%;"></i>&nbsp;
            <span class="login-text">(855)-694-2837</span> 
        </div>
    </div>
    <div id="nav-header">
        <div class="container">
            
          
              <!--  <a id="logo" >
                    <img alt="CellGate TrueCloud Logo" src="../../../assets//images/CellGate_TrueCloud.png" style="height:150px; width: 180px; margin:10px 0px -110px -4px;"/>
                </a>-->
           
        </div>
    </div>
</nav>