<div class="container-fluid bg-primary">
    <div>
        <input type="hidden" id="fileName" [value]="invite.DownloadName" />
        <input type="hidden" id="hdnBase64Data" [value]="invite.Result" />
        <div *ngIf="invite.Status" class="mt-100">
             <div class="row" >
                <div class="col-md-6 col-md-offset-3">
                    <p id ="h1">Scan virtual key below for entry:<p>
                    <table class="table table-sm" style="margin:auto">
                        <tbody>
                            <tr>
                                <td> Virtual Key Name </td>
                                <td> {{invite.InviteName}}</td>
                            </tr>
                            <tr *ngIf="invite.StartDate && invite.EndDate">
                                <td> Start & End Date</td>
                                <td> {{invite.StartDate}} to {{invite.EndDate}} </td>
                            </tr>
                            <tr>
                              <td>Locations</td>
                              <td class="comma-list">
                                <span *ngFor="let loc of invite.Properties">{{loc}} </span>
                              </td>
                            </tr>                           
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" >
                <p class="" id ="h1"> DO NOT SCREENSHOT QR CODE BELOW</p>            
              </div>
            <div class="row" id="values" >
                <div class="col-md-6 col-md-offset-3">
                    <img id="barcodeImage" [src]="'data:image/png;base64,'+ invite.Result" />
                </div>
            </div>
            <div class="row" >
                <p class="" id="h1" >  The QR Code refreshes on every use. <br>Once this code is used refresh the page to generate the new code.</p>
             <br>
              </div>
              
              <!-- <div class="row text-center" >
                <button (click)="GenerateQRCode()" ><img class="brand-btn-qrcode" src="assets/brandIcons/Generate_QR_code.svg"></button>
             <br>
              </div> -->
          
        </div>
        <div *ngIf="!invite.Status" class="mt-100">
            {{invite.Result}}
        </div>
    </div>

<script>

    var fileName = document.getElementById("fileName").value;
    var base64Data = document.getElementById("hdnBase64Data").value;
    var a = document.createElement("a"); //Create <a>
    a.href = "data:image/png;base64," + base64Data;
    a.download = fileName; //File name Here
    a.click(); //Downloaded file
</script>
