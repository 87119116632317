import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
// import { ApiHandler, NotificationService } from '../../http/user/';
import { environment } from '../../../../../environments/environment';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from './user.service';
import { throwIfAlreadyLoaded } from 'src/app/core/ensureModuleLoadedOnceGuard';
// import { IUserModel } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  USER: any;
  accountId: number;
  constructor(
    private apiHandler: ApiHandler,
    private notificationService: NotificationService,
    private userService: UserService
  ) {
    this.USER = this.userService.UserDetailsList;
    this.userService.UserDetails.subscribe(user => {
      this.USER = user;
      this.accountId = this.USER.AccountID;
    });
  }

  getBillingInfo() {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getBillingInfo}${this.USER.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getAccountBillingTotals() {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.accountBillingTotals}${this.USER.AccountID}`
      )
      .pipe(map((response) =>{
        return  response[0] || { Total: 0}
      }));
  }

  getInvoices(
    limit: number = 50,
    page: number = 1,
    count: number = 20,
    sort: Array<any> = ['ID DESC']
  ) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getInvoices}${this.USER.AccountID}&limit=${limit}&page=${page}&count=${count}&sort=${sort}`
      )
      .pipe(
        map((response) => response || null),
        catchError(this.handleError)
      );
  }

  getCardInfoForAccount() {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getCardInfoForAccount}${this.USER.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getAccountCredits(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getAccountCredits}${this.USER.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getAccBillingInfo() {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getAccBillingInfo}${this.USER.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getInvoiceByAccountID(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getInvoiceByAccountID}${this.USER.AccountID}`
      )
      .pipe(map((response) => response));
  }

  getPaymentInfo(accountId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getPaymentInfo}${this.USER.AccountID}`
      )
      .pipe(
        map((response: any) => {
          if (response)
            if(this.USER.PaymentGatewayTypeID == 1 && response.CardInfoStripe){
              return {
                Type: response.CardInfoStripe.brand,
                ExpirationMonth: response.CardInfoStripe.exp_month,
                ExpirationYear: response.CardInfoStripe.exp_year,
                LastFour: response.CardInfoStripe.last4,
                CardName: response.CardInfoStripe.name,
                CardID: response.CardInfoStripe.id,
              };
            }else if(this.USER.PaymentGatewayTypeID == 2 && response.CardInfo){
              return {
                Type: response.CardInfo.brand,
                ExpirationMonth: response.CardInfo.exp_month,
                ExpirationYear: response.CardInfo.exp_year,
                LastFour: response.CardInfo.last4,
                CardName: response.CardInfo.name,
                CardID: response.CardInfo.id,
              };
            }
            

          return null;
        })
      );
  }

  getInvoiceByInvoiceId(invoiceId: number) {
    return this.apiHandler
      .get(
        `${environment.API_URL.Accountkit.getInvoiceByInvoiceId}${invoiceId}`
      );
  }
  private handleError(error: any) {
    return of(error.error);
  }

  SendEmailOnCardChange(accountId) {
    let url = `${environment.API_URL.Accountkit.SendEmailOnCardChange}${accountId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }

  deleteCard(accountId) {
    let url = `${environment.API_URL.Accountkit.deleteCard}${accountId}`;
    return this.apiHandler.post(url, {}).pipe(catchError(this.handleError));
  }
  createEmail(initialValues) {
    initialValues = initialValues || {};
    return this.Email(initialValues);
    //return manager.createEntity('Email', initialValues);
  }
  Email(initialValues) {
    return {
      ID: 0,
      ContactID: initialValues.ContactID,
      EmailAddress: initialValues.EmailAddress,
      IsDeleted: false,
    };
  }
  UpdateBillingInfo(billingInfo) {
    let url = `${environment.API_URL.Accountkit.UpdateBillingInfo}`;
    return this.apiHandler
      .post(url, billingInfo)
      .pipe(catchError(this.handleError));
  }
  saveCard(accountId, sourceId, customerId) {
    let url = `${environment.API_URL.Accountkit.saveCard}`;
    let obj = {
      AccountId: accountId,
      SourceID: sourceId,
      CustomerID: customerId
    };
    return this.apiHandler.post(url, obj).pipe(catchError(this.handleError));
  }
  getChargeTypes() {
    let url = `${environment.API_URL.Accountkit.getChargeTypes}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }
  getAccountInfo(accountId) {
    let url = `${environment.API_URL.Accountkit.getAccountInfo}${accountId}`;
    return this.apiHandler.get(url).pipe(catchError(this.handleError));
  }


  emailInvoice(invoiceID, email) {
			var data = {
				'InvoiceId': invoiceID,
				'ToAddresses': [email]
			};
      return this.apiHandler.post(environment.API_URL.Accountkit.url + '/api/Invoice/EmailInvoice', data ).pipe(catchError(this.handleError));
		}
}
