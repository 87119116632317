import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import { map, catchError, of } from 'rxjs';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recurring-invite',
  templateUrl: './recurring-invite.component.html',
  styleUrls: ['./recurring-invite.component.scss']
})
export class RecurringInviteComponent implements OnInit,OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiHandler: ApiHandler,
    private http: HttpClient
  ) {}
  ngOnDestroy(): void {
    sessionStorage.removeItem('isShowQRCode');
  }
  token: string;
  id:string;
  invite: any = {};
  isShowQRCode:boolean=false;
  ngOnInit(): void {
    this.invite = this.route.snapshot.data['data'];
    if(this.invite.InviteType=='Temporary')
    {
      this.isShowQRCode=true;
    }
    this.route.queryParams.subscribe((params: any) => {
      this.token = params.d;
      this.id = params.id;
    });
  }


  private handleError(error: any) {
    return of(error.error);
  }

}
