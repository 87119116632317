import { Component, Injectable, NgModule } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, NavigationStart, Route, Router, RouterModule, RouterStateSnapshot, Routes, UrlSegment, UrlTree } from '@angular/router';
import { CommonModule } from '@angular/common';  ;  
import { LoginComponent } from './modules/auth//login/login.component';  
import { LanguageTranslationModule } from './core/services/language-translation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsComponent } from './core/errors';
import { Observable, of } from 'rxjs';
import { Authenticationservice } from './core/authentication/authenticationService';
import { UserService } from './shared/services/http';
import { BareLayoutComponent } from './layout/bare-layout/bare-layout.component';
import { NonWebAccessUserComponent } from './modules/others/non-web-access-user/non-web-access-user.component';
import { WatchmanSystemConfigurationComponent } from './modules/others/watchman-system-configuration/watchman-system-configuration.component';
import { AuthGuard } from './core/guard';
import { AccountRoutingModule } from './modules/account/account-routing.module';

@Injectable()
export class UserLoad implements CanLoad {
  constructor(private userService: UserService, private authService: Authenticationservice){}
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.userService.UserDetailsList)
      return of(true);
    return this.authService.getUserDetailsModule();
  }
}
@Injectable()
export class SiteGuard implements CanActivate {
  constructor( private authService: Authenticationservice, private router:Router){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.authService.isAuthenticated())
    {
        return true;
    }   
      else{
        return this.router.parseUrl('/auth');
      }
   
  }
}

 @Injectable()
export class AccountGuard implements CanActivate {
  constructor(private authService: Authenticationservice, private router:Router){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url: string = state.url;
    if(url== "/Account"){
    return  this.router.parseUrl('/auth');
  }
    else
    return true;
}
} 
const routes: Routes = [{  
  path: '',  
  redirectTo: 'auth',  
  pathMatch: 'full'  
},{  
  path: 'auth',
  loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  canActivate: [AuthGuard],
},
{
  path: 'site',
  loadChildren: () => import('./modules/site/site.module').then((m) => m.SiteModule),
  canLoad: [UserLoad],
  canActivate:[SiteGuard]
},
{
  path: 'server-error',
  loadChildren: () => import('./layout/server-error/server-error.module').then((m) => m.ServerErrorModule)
},
{
  path: 'access-denied',
  loadChildren: () => import('./layout/access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
},
{
  path: 'not-found',
  loadChildren: () => import('./layout/not-found/not-found.module').then((m) => m.NotFoundModule)
},/* 
{
  path: 'QRCode',
  loadChildren: () => import('./modules/qrcode-invite/qrcode-invite.module').then((m) => m.QRcodeInviteModule)
},
{
  path: 'Account',
  loadChildren: () => import('./modules/callgroup-master-user/callgroup-master-user.module').then((m) => m.CallgroupMasterUserModule)
}, */
{
  path: 'Account',
  loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
  canActivate:[AccountGuard]
},
{
  path:'nonwebaccessuser',
  component: BareLayoutComponent,
  children:[
    {
      path:'',
      component: NonWebAccessUserComponent
    }
  ] 
},
{
  path:'watchmansystemconfiguration',
  component: BareLayoutComponent,
  children:[
    {
      path:'',
      component: WatchmanSystemConfigurationComponent
    }
  ] 
},
{ path: 'error', component: ErrorsComponent, data: { error: 404 } },
  { path: '**', redirectTo:'auth',pathMatch:'full' }
] 
@NgModule({
 //  declarations: [LoginComponent],  
  imports: [ CommonModule, RouterModule.forRoot(routes),TranslateModule],
  exports: [RouterModule],
  providers: [UserLoad, AuthGuard, SiteGuard,AccountGuard]
})
export class AppRoutingModule { 
  static components = [ NonWebAccessUserComponent, WatchmanSystemConfigurationComponent,AccountRoutingModule];
  constructor(private router: Router,private authService: Authenticationservice) {
    this.router.errorHandler = (error: any) => {
       // this.router.navigate(["/auth/login"]); // or redirect to default route
        if(this.authService.isAuthenticated())
    {
      return this.router.parseUrl('/');
    }   
      else{
        return this.router.parseUrl('/auth/login');
      }
    }
  }
}
