import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/shared/services/http';
import { environment } from 'src/environments/environment';
import { MasterUserService } from '../../callgroup-master-user/master-user.service';

@Component({
  selector: 'app-watchman-system-configuration',
  templateUrl: './watchman-system-configuration.component.html',
  styleUrls: ['./watchman-system-configuration.component.scss']
})
export class WatchmanSystemConfigurationComponent implements OnInit {
  videoURL:string;
  constructor(private router: Router,private route: ActivatedRoute, 
    private userService: UserService,
    private masterUserService: MasterUserService, private NS: NotificationService) {
      this.userService.changewatchmanStatus(true);
     }

  ngOnInit(): void {
    this.masterUserService.getWatchmanSystemConfiguration().subscribe((response) => {
      if(response){
        this.videoURL = response.VideoUrl;
      }
    });
  }

}
